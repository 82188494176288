/**
 * Translates the term unit to Russian
 */
export function formatTermUnit(unit: string,) {
  // backend can return capitalized units somehow
  switch (unit.toLocaleLowerCase()) {
    case "day": return "дней";
    case "week": return "недель";
    case "month": return "месяцев";
    case "year": return "лет";
    default: return unit;
  }
}
