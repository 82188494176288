import { Box, Icon, Text, } from "@chakra-ui/react";
import { CheckIcon, } from "@heroicons/react/20/solid";

export interface IIndicatorProps {
  /**
   * 1. success - for paid payments
   * 2. danger - for overdue payments
   * 3. default - for upcoming payments
   */
  variant?: "success" | "danger" | "default";
  /**
   * The text to display in the indicator.
   * Basically the amount of the payment.
   */
  text?: string;
}

const bgMap = {
  success: "success",
  danger: "red.100",
  default: "gray.50",
};

/**
 * Circled indicator of the operation payment in list.
 */
export function Indicator({ text, variant = "default", }: IIndicatorProps,) {
  return (
    <Box
      boxSize="38px"
      bg={bgMap[variant]}
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {variant === "success" && <Icon color="white" as={CheckIcon} />}
      {variant !== "success" && <Text>{text}</Text>}
    </Box>
  );
}
