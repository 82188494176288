import { Button, FormControl, FormErrorMessage, FormLabel, Input, Spinner, Stack, } from "@chakra-ui/react";
import { useSMSFormState, useSMSFormStateReaction, } from "./hooks";
import { ESMSFormState, } from "../../../../store/slices/smsFormSliceCreator";
import { SyntheticEvent, } from "react";

export function SMSForm() {
  const smsForm = useSMSFormState();
  useSMSFormStateReaction();

  const handleSubmit = (): void => {
    if (smsForm.formState === ESMSFormState.initial) {
      smsForm.setFormState(ESMSFormState.validating,);
    }
  };

  const handleChange = (e: SyntheticEvent<HTMLInputElement>,) => {
    smsForm.setCode(e.currentTarget.value,);
    smsForm.setErrorMessage("",);
  };

  return (
    <Stack spacing={4}>
      <FormControl isInvalid={!!smsForm.errorMessage}>
        <FormLabel>
          Код из SMS
        </FormLabel>
        <Input
          name="otp"
          autoComplete="one-time-code"
          value={smsForm.code}
          maxLength={4}
          autoFocus
          onChange={handleChange}
          inputMode="numeric"
        />
        <FormErrorMessage>{smsForm.errorMessage}</FormErrorMessage>
      </FormControl>
      <Button colorScheme="brand" onClick={handleSubmit}>
        {smsForm.formState === ESMSFormState.initial
          ? "Продолжить"
          : (
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="white"
            />
            )}
      </Button>
    </Stack>
  );
}
