/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import {
  IRepaymentScheduleByIdResponse,
  operationByIdQueryConfig,
  repaymentSchedulesByIdQueryConfig,
} from "../../../../api";
import { useQuery, } from "@tanstack/react-query";
import { useParams, } from "react-router-dom";
import { Box, Button, Card, CardBody, Heading, HStack, Skeleton, SkeletonCircle, Stack, Text, } from "@chakra-ui/react";
import { formatPrice, } from "../../../../utils/formatPrice";
import { formatDate, } from "../../../../utils/formatDate";
import { Indicator, } from "./Indicator";
import { asPaymentSplit, EPaymentSplit, getAllUnpaidPayments, hasUpcomingPayment, shouldBePaid, } from "./utils";
import { useNavigateToPayment, } from "../../PartialPayment/hooks";
import { useFlag, } from "@unleash/proxy-client-react";

const indicatorVariantMap: Record<number, "default" | "success" | "danger"> = {
  1: "default",
  2: "success",
  3: "danger",
};

/**
 * List of payments for the operation.
 *
 * It has a tricky structure to understand more about what data type is used for rendering
 * consider reading the description of TPaymentListSplitType.
 *
 * @see TPaymentListSplitType
 */
export function PaymentList() {
  const operationId = useParams().id!;
  const navigateToPayment = useNavigateToPayment();
  const isPaymentsEnabled = useFlag("payments.all",);

  const paymentSplitQuery = useQuery({
    ...repaymentSchedulesByIdQueryConfig(parseInt(operationId,),),
    select: asPaymentSplit,
  },);

  const operation = useQuery(operationByIdQueryConfig(operationId,),);

  const handlePayNow = (part: IRepaymentScheduleByIdResponse[],) => () => {
    navigateToPayment(part.map(p => p.number,), parseInt(operationId,),);
  };

  const handlePayAll = () => {
    if (paymentSplitQuery.isSuccess) {
      navigateToPayment(
        getAllUnpaidPayments(paymentSplitQuery.data,).map(i => i.number,),
        parseInt(operationId,),
      );
    } else {
      // this is the corner case it should be reported in the monitoring system
      console.error("Schedule is not loaded",);
    }
  };

  return (
    <Box>
      <Box pt={8} pb={2}>
        <Skeleton isLoaded={!operation.isLoading}>
          <Heading fontSize="xl">
            Осталось оплатить
            {" "}
            {operation.isSuccess && formatPrice(operation.data.operation.remainingAmount / 100, 0,)}
          </Heading>
        </Skeleton>
      </Box>
      {paymentSplitQuery.isLoading && (
        <Stack spacing={3} py={4}>
          {new Array(4,).fill(0,).map((_, i,) => (
            <HStack key={i} spacing={4} py={2}>
              <SkeletonCircle boxSize="38px" />
              <Skeleton w="50%" h={6} />
              <Box flex="1" />
              <Skeleton w="20%" h={6} />
            </HStack>
          ),)}
        </Stack>
      )}
      <Stack spacing={3}>
        {paymentSplitQuery.isSuccess && paymentSplitQuery.data.map((part, spiltType,) =>
          part.length > 0 && (
            <Card
              key={spiltType}
              variant={shouldBePaid(spiltType,) ? "elevated" : "unstyled"}
            >
              <CardBody p={3}>
                <Stack spacing={3}>
                  {part.map((payment, j,) => (
                    <Box
                      key={payment.number}
                      py={1}
                      color={EPaymentSplit.Paid === spiltType ? "gray.500" : undefined}
                    >
                      <HStack spacing={4}>
                        <Indicator
                          variant={indicatorVariantMap[payment.status]}
                          text={(spiltType + j + 1).toString()}
                        />
                        <Box flex="1">
                          <Text>{formatDate(new Date(payment.date,),)}</Text>
                          {payment.status === 3 && (
                            <Text fontSize="sm" color="red.500">Просрочен</Text>
                          )}
                        </Box>
                        <Box>
                          <Text>{formatPrice(payment.amount / 100, 0,)}</Text>
                        </Box>
                      </HStack>
                    </Box>
                  ),)}
                </Stack>
                {isPaymentsEnabled && shouldBePaid(spiltType,) && (
                  <Button mt={3} colorScheme="brand" w="100%" onClick={handlePayNow(part,)}>
                    {spiltType === EPaymentSplit.FstUpcoming && "Оплатить сейчас"}
                    {spiltType === EPaymentSplit.Overdue && "Оплатить долг"}
                  </Button>
                )}
              </CardBody>
            </Card>
          )
          ,)}
      </Stack>
      {paymentSplitQuery.isSuccess && hasUpcomingPayment(paymentSplitQuery.data,) && (
        <Box pt={4}>
          <Button colorScheme="black" w="100%" onClick={handlePayAll}>
            Погасить полностью
          </Button>
        </Box>
      )}
    </Box>
  );
}
