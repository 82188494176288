import { Box, Button, FormControl, FormErrorMessage, Heading, Input, Stack, Text, } from "@chakra-ui/react";
import { formatPrice, } from "../../../../utils/formatPrice";
import { useQuery, } from "@tanstack/react-query";
import { operationByIdQueryConfig, } from "../../../../api";
import { EPartialPaymentState, usePartialPaymentStore, } from "../../../../store/partialPaymentStore";
import { usePartialPaymentFormReaction, } from "./hooks/usePartialPaymentFormReaction";
import { PaymentCardPicker, } from "../../PaymentCardPicker";
import { useFlag, } from "@unleash/proxy-client-react";

export function PartialPaymentForm() {
  const isPaymentCardsEnabled = useFlag("payments.cards",);

  const operationId = usePartialPaymentStore(state => state.operationId,);
  const operation = useQuery(operationByIdQueryConfig(operationId.toString(),),);

  const amountValue = usePartialPaymentStore(state => state.amountStr,);
  const setAmountValue = usePartialPaymentStore(state => state.setAmountStr,);
  const setFormState = usePartialPaymentStore(state => state.setState,);
  const formState = usePartialPaymentStore(state => state.state,);
  const errorMsg = usePartialPaymentStore(state => state.errorMsg,);

  usePartialPaymentFormReaction();

  const handlePayment = () => {
    setFormState(EPartialPaymentState.Validating,);
  };

  return (
    <>
      <Stack py={4} spacing={4}>
        <Heading fontSize="xl">
          Введите сумму, которую можете оплатить
        </Heading>
        <FormControl isInvalid={!!errorMsg}>
          <Input
            autoFocus
            inputMode="numeric"
            onChange={(e,) => {
              setFormState(EPartialPaymentState.Initial,);
              setAmountValue(e.currentTarget.value,);
            }}
            value={amountValue}
          />
          <FormErrorMessage>
            {errorMsg}
          </FormErrorMessage>
        </FormControl>
        <Text
          color="brand.500"
          py={1}
          onClick={() => { setAmountValue((operation.data!.operation.remainingAmount / 100).toString(),); }}
        >
          весь долг
          {" "}
          {formatPrice(operation.data!.operation.remainingAmount / 100, 0,)}
        </Text>
      </Stack>
      <Box mt={4}>
        {isPaymentCardsEnabled && <PaymentCardPicker />}
        <Button
          isLoading={formState !== EPartialPaymentState.Initial}
          onClick={handlePayment}
          mt={2}
          colorScheme="brand"
          width="100%"
        >
          Оплатить
        </Button>
      </Box>
    </>
  );
}
