import { usePinFormStore, } from "../../../../../store/pinFormStore.ts";

export function usePinFormState() {
  const pin = usePinFormStore(state => state.pin,);
  const errorMessage = usePinFormStore(state => state.errorMessage,);
  const state = usePinFormStore(state => state.state,);
  const setState = usePinFormStore(state => state.setState,);
  const setPin = usePinFormStore(state => state.setPin,);
  const setError = usePinFormStore(state => state.setErrorMessage,);
  const reset = usePinFormStore(state => state.reset,);

  return {
    pin, errorMessage, state, setPin, setError, reset, setState,
  };
}
