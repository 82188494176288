import { Box, Button, Center, Heading, HStack, Icon, Text, } from "@chakra-ui/react";
import { formatPrice, } from "../../utils/formatPrice";
import { CheckCircleIcon, } from "@heroicons/react/24/solid";
import { RoutesMap, } from "../../routes";
import { LoaderFunctionArgs, redirect, useNavigate, useParams, useSearchParams, } from "react-router-dom";
import { useQuery, } from "@tanstack/react-query";
import { operationByIdQueryConfig, } from "../../api";
import { PageLoader, } from "../../components/shared/PageLoader";

export const PAYMENT_AMOUNT_SEARCH_PARAM = "pa";

export function PaymentSuccessPage() {
  const [sp,] = useSearchParams();
  const params = useParams();
  const operationId = params.id!;
  const totalPaymentAmount = sp.get(PAYMENT_AMOUNT_SEARCH_PARAM,)!;
  const operation = useQuery(operationByIdQueryConfig(operationId,),);
  const navigate = useNavigate();

  if (!operation.isSuccess) {
    return <PageLoader />;
  }

  return (
    <>
      <Box pt={8} pb={17}>
        <Center>
          <Box>
            <Heading textAlign="center" fontSize="8xl">
              {formatPrice(parseInt(totalPaymentAmount,), 0,)}
            </Heading>
            <Text textAlign="center">
              Платеж за покупку
              <br />
              в
              {" "}
              { operation.data.operation.merchantName }
            </Text>
            <HStack spacing={2} py={6} justifyContent="center">
              <Icon as={CheckCircleIcon} boxSize={6} color="success" />
              <Text color="success">Оплачено успешно</Text>
            </HStack>
          </Box>
        </Center>
      </Box>
      <Box pt={4}>
        <Button w="100%" colorScheme="gray" onClick={() => { navigate(RoutesMap.index,); }}>Закрыть</Button>
      </Box>
    </>
  );
}

PaymentSuccessPage.loader = ({ request, params, }: LoaderFunctionArgs,) => {
  const url = new URL(request.url,);
  const sp = url.searchParams;
  if (!params.id || !sp.has(PAYMENT_AMOUNT_SEARCH_PARAM,)) {
    return redirect(RoutesMap.index,);
  }
  return null;
};
