import { createContext, useContext, } from "react";
import { IAppModalProps, } from "../AppModal";

export interface IModalContext {
  showModal: (modal: Omit<IAppModalProps, "isBlocked" | "block">,) => void;
  /** hides any modal displayed right now */
  hideModal: () => void;
  isModalOpen: boolean;
}

export const modalContext = createContext<IModalContext>({} as IModalContext,);

/**
 * Hook that allows you to open the modal.
 *
 * @example
 * const { showModal } = useAppModal();
 * showModal({
 *   content: (onClose) => <MyModal onClose={onClose}>,
 *   header: "My Modal",
 * });
 *
 * @see AppModal
 * @see AppModalProvider
 */
export function useAppModal() {
  return useContext(modalContext,);
}
