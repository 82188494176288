import { useQuery, } from "@tanstack/react-query";
import { operationsQueryConfig, repaymentSchedulesByIdQueryConfig, } from "../../../../../api";
import { usePaymentsStore, } from "../../../../../store/paymentsStore";

// THIS LOGIC WILL BE REPLACED IN THE FUTURE.
// For now, there is no API endpoint for fetching all repayment schedules
// to make a visual representation of the future payments
// we're temporarily using the operation endpoint and requesting schedules for the first operation
export function useRepaymentSchedules() {
  const operations = useQuery(operationsQueryConfig(),);
  const fstUnpaid = operations.data?.data.find(item => item.operationStatus !== 2,);

  const schedule = useQuery(repaymentSchedulesByIdQueryConfig(fstUnpaid?.id,),);
  const successfulPayments = usePaymentsStore(state => state.successfulPayments,);

  const sortedSchedule = schedule
    .data
    ?.filter(item => item.status !== 2,)
    .filter(item =>
      !successfulPayments
        .find(payment => payment.number === item.number,),)
    .sort((a, b,) => {
      if (new Date(a.date,) < new Date(b.date,)) {
        return -1;
      } else {
        return 1;
      }
    },)
    .sort(a => a.status === 3 ? -1 : 1,) ?? [];

  return {
    schedule,
    sortedSchedule,
    operations,
    operation: fstUnpaid,
  };
}
