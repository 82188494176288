import { useLoginFormStore, } from "../../store/loginFormStore";
import { redirect, } from "react-router-dom";
import { EPhoneFormState, } from "../../store/slices/phoneFormSliceCreator";
import { AbsoluteCenter, Box, Heading, Stack, Text, } from "@chakra-ui/react";
import { usePhoneFormState, } from "../../components/features/LoginPage/PhoneNumberForm/hooks";
import { SMSForm, } from "../../components/features/LoginPage/SMSForm";
import { SMSResendTimer, } from "../../components/features/LoginPage";

export function OtpPage() {
  const phoneForm = usePhoneFormState();

  return (
    <Box position="relative" h="calc(100vh - 84px * 2)">
      <AbsoluteCenter axis="both">
        <Stack spacing={9} minW="300px">
          <div>
            <Heading mb={2} textAlign="center">Введите код из SMS</Heading>
            <Text size="sm" color="gray.500" align="center">
              Отправили на
              {" "}
              { phoneForm.phone }
            </Text>
          </div>
          <SMSForm />
          <SMSResendTimer />
        </Stack>
      </AbsoluteCenter>
    </Box>
  );
}

OtpPage.loader = () => {
  const formState = useLoginFormStore.getState();

  if (formState.phoneFormState !== EPhoneFormState.submitted) {
    return redirect("/login",);
  }

  formState.clearSMSForm();
  return null;
};
