import { useLoginFormStore, } from "../../../../../store/loginFormStore";

export function useSMSFormState() {
  const code = useLoginFormStore(state => state.smsCode,);
  const setCode = useLoginFormStore(state => state.setSMSCode,);
  const formState = useLoginFormStore(state => state.smsFormState,);
  const setFormState = useLoginFormStore(state => state.setSMSFormState,);
  const errorMessage = useLoginFormStore(state => state.errorMessage,);
  const setErrorMessage = useLoginFormStore(state => state.setErrorMessage,);

  return {
    code,
    setCode,
    formState,
    setFormState,
    errorMessage,
    setErrorMessage,
  };
}
