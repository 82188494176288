export interface IPaymentInfo {
  number: number;
  date: string;
  amount: number;
  status: EPaymentInfoStatus;
}

export enum EPaymentInfoStatus {
  Planned = 1,
  Paid = 2,
  Overdue = 3,
}
