import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  HStack,
  PinInput,
  PinInputField,
  Stack,
} from "@chakra-ui/react";
import { useEffect, } from "react";
import { usePinFormState, usePinFormStateReaction, } from "./hooks";
import { EPinState, } from "../../../../store/pinFormStore";

/**
 * This component is unfinished due to luck of requirements
 */
export function PinForm() {
  const { setError, pin, errorMessage, setPin, setState, } = usePinFormState();
  usePinFormStateReaction();

  useEffect(() => {
    setError("",);
  }, [pin,],);

  const handleClick = () => {
    setState(EPinState.Validating,);
  };

  return (
    <Stack spacing={16}>
      <FormControl isInvalid={!!errorMessage}>
        <Center>
          <Box>
            <HStack>
              <PinInput variant="brand" mask type="number" placeholder="" value={pin} onChange={setPin}>
                <PinInputField maxW="42px" />
                <PinInputField maxW="42px" />
                <PinInputField maxW="42px" />
                <PinInputField maxW="42px" />
              </PinInput>
            </HStack>
            <Center>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </Center>
          </Box>
        </Center>
      </FormControl>
      <Button colorScheme="brand" onClick={handleClick}>
        Продолжить
      </Button>
    </Stack>
  );
}
