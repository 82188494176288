export const PIN_ERROR_MESSAGES = {
  pinIsTooShort: "Введите 4 цифры",
  networkError: "Неправильный код",
};

export function getValidationErrorMessage(pin: string,) {
  switch (true) {
    case pin.length < 4: return PIN_ERROR_MESSAGES.pinIsTooShort;
    default: return null;
  }
}
