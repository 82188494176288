export const PHONE_ERROR_MESSAGES = {
  phoneIsEmpty: "Введите номер телефона",
  phoneIsTooShort: "Введите номер телефона полностью",
  default: "Введите корректный номер телефона",
  unexpectedServerError: "Не удалось отправить код, попробуйте ещё раз или зайдите чуть позже.",
};

export const getValidationErrorMessage = (phone: string,) => {
  switch (true) {
    case phone.length === 1: return PHONE_ERROR_MESSAGES.phoneIsEmpty;
    case phone.length < 10: return PHONE_ERROR_MESSAGES.phoneIsTooShort;
    default: return PHONE_ERROR_MESSAGES.default;
  }
};

/**
 * Validates escaped form number
 */
export const validatePhone = (phone: string,): boolean => {
  return /\d{10}/i.test(phone,);
};

/**
 * Removes all not numeric symbols and initial (+7) part from phone number input
 */
export const clearPhone = (phone: string,): string => phone.replace(/(^\+7)|\D*/ig, "",);
