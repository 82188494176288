import { StateCreator, } from "zustand";

export enum ESMSFormState {
  initial,
  validating,
  validated,
  submitting,
  submitted,
}

export interface ISMSFormSlice {
  smsCode: string;
  smsFormState: ESMSFormState;
  errorMessage: string;
  setSMSCode: (phoneNumber: string) => void;
  clearSMSForm: () => void;
  setSMSFormState: (state: ESMSFormState) => void;
  setErrorMessage: (errorMessage: string) => void;
}

/**
 * Contains all information about sms form.
 */
export const smsFormSliceCreator: StateCreator<ISMSFormSlice> = set => ({
  smsCode: "",
  errorMessage: "",
  smsFormState: ESMSFormState.initial,
  setSMSCode: (smsCode,) => {
    set(() => ({
      smsCode: smsCode.replace(/\D|.{5,}/ig, "",),
    }),);
  },
  clearSMSForm: () => {
    set(() => ({
      smsCode: "",
      smsFormState: ESMSFormState.initial,
      errorMessage: "",
    }),);
  },
  setSMSFormState: (state: ESMSFormState,) => {
    set(() => ({ smsFormState: state, }),);
  },
  setErrorMessage: (message,) => {
    set(() => ({ errorMessage: message, }),);
  },
});
