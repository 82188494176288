import { useEffect, } from "react";
import { useSMSFormState, } from "./useSMSFormState";
import { ESMSFormState, } from "../../../../../store/slices/smsFormSliceCreator";
import { usePhoneFormState, } from "../../PhoneNumberForm/hooks";
import { useNavigate, } from "react-router-dom";
import { useOTPVerificationMutationReaction, } from "./useOTPVerificationMutationReaction";
import { RoutesMap, } from "../../../../../routes";

export const SMS_FORM_ERROR_MESSAGES = {
  empty: "Введите код",
  tooShort: "Не менее 4х цифр",
  incorrect: "Неправильный код",
  unexpectedServerError: "Не удалось отправить код, попробуйте ещё раз или зайдите чуть позже.",
};

export function useSMSFormStateReaction() {
  const navigate = useNavigate();
  const phoneForm = usePhoneFormState();
  const smsForm = useSMSFormState();

  const verifyCode = useOTPVerificationMutationReaction();

  useEffect(() => {
    switch (smsForm.formState) {
      case ESMSFormState.validating: {
        if (smsForm.code.length === 0) {
          smsForm.setErrorMessage(SMS_FORM_ERROR_MESSAGES.empty,);
          smsForm.setFormState(ESMSFormState.initial,);
          return;
        }
        if (smsForm.code.length < 4) {
          smsForm.setErrorMessage(SMS_FORM_ERROR_MESSAGES.tooShort,);
          smsForm.setFormState(ESMSFormState.initial,);
          return;
        }
        smsForm.setFormState(ESMSFormState.validated,);
        return;
      }
      case ESMSFormState.validated: {
        verifyCode.mutate({ phone: phoneForm.getEscapedPhoneNumber(), code: smsForm.code, },);
        smsForm.setFormState(ESMSFormState.submitting,);
        return;
      }
      case ESMSFormState.submitted: {
        navigate(RoutesMap.login.pin,);
        return;
      }
    }
  }, [smsForm.formState,],);
}
