/**
 * Base border radius theme rewritten.
 * In our application only 10,12,16px borders are used. To avoid a changes
 * introduction into every chakra component it will be rewritten here
 * on theme level.
 * In case of emergency if you will found a single component with unspecified
 * or different border radii please rewrite it on component level.
 */
export const borderRadius = {
  radii: {
    "none": "0",
    "sm": "10px",
    "base": "12px",
    "md": "12px",
    "lg": "16px",
    "xl": "24px",
    "2xl": "24px",
    "3xl": "24px",
    "full": "9999px",
  },
};
