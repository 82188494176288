import { useIsFetching, useIsMutating, useMutation, } from "@tanstack/react-query";
import { useEffect, } from "react";
import { usePersistStore, } from "../../../store/persistStore";
import { EJWTStatus, validateJWT, } from "../../../utils";
import { isNetworkError, tokenRefreshMutationFn, } from "../../../api";
import { useNavigate, } from "react-router-dom";
import { RoutesMap, } from "../../../routes";

/**
 * Yes this is component. NO, it can't be written elsewhere.
 * It's a component because it needs to be rendered in the tree to have access to hooks.
 * Keep it in the root of the application.
 *
 * This component is responsible for refreshing JWT token when it's about to expire.
 */
export function TokenRefresher() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const navigate = useNavigate();
  const token = usePersistStore(state => state.jwt,);
  const deviceToken = usePersistStore(state => state.deviceJWT,);
  const clearToken = usePersistStore(state => state.clearJwt,);

  const setJWT = usePersistStore(state => state.setJwt,);

  const refreshTokenMutation = useMutation({
    mutationFn: tokenRefreshMutationFn,
    onSuccess: (data,) => {
      setJWT(data.token,);
    },
    onError: (e,) => {
      if (isNetworkError(e,) && e.internalCode !== 10000) {
        // This is an API error, and it's not 500.
        // For some reason token can't be refreshed. Logout.
        clearToken();
        navigate(RoutesMap.login.index,);
      }
    },
    retry: 10,
  },);

  useEffect(() => {
    if ((isFetching || isMutating) && !refreshTokenMutation.isPending) {
      switch (validateJWT(deviceToken,)) {
        case EJWTStatus.Expired:
        case EJWTStatus.RefreshRequired: {
          navigate(RoutesMap.login.pin,);
          return;
        }
      }
      switch (validateJWT(token,)) {
        case EJWTStatus.RefreshRequired: {
          refreshTokenMutation.mutate();
          break;
        }
      }
    }
  }, [isFetching, token, isMutating,],);

  return null;
}
