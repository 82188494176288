import { create, } from "zustand";
import { devtools, } from "zustand/middleware";
import { IPhoneFormSlice, phoneFormSliceCreator, } from "./slices/phoneFormSliceCreator";
import { ISMSFormSlice, smsFormSliceCreator, } from "./slices/smsFormSliceCreator";

export type LoginFormState = IPhoneFormSlice & ISMSFormSlice;

export const useLoginFormStore = create<LoginFormState>()(
  devtools(
    (...a) => ({
      ...phoneFormSliceCreator(...a,),
      ...smsFormSliceCreator(...a,),
    }),
    { name: "login-store", },
  ),
);
