import { Box, Flex, Heading, Image, Skeleton, SkeletonCircle, Stack, Text, } from "@chakra-ui/react";
import { formatPrice, } from "../../../../utils/formatPrice";
import { useGetOperationFromCache, } from "../../hooks";
import { CircleImagePlaceholder, } from "../../../shared/CircleImagePlaceholder";
import { useQuery, } from "@tanstack/react-query";
import { operationCommissionQueryConfig, } from "../../../../api";
import { useParams, } from "react-router-dom";

/**
 * Operation page main information block.
 * Should display the price, logo, and the merchant name
 */
export function OperationMainInfo() {
  const operationId = parseInt(useParams().id!,);
  const {
    data,
    isLoading,
  } = useGetOperationFromCache({ operationId, },);
  const commission = useQuery(operationCommissionQueryConfig(operationId.toString(),),);

  // magic number 10000 is needed to preserve line length for skeleton
  const commissionAmount = (commission.data?.amount ?? 10000) / 100;

  return (
    <Box id="info" py={10}>
      <Flex>
        <Box flex="1">
          <Stack spacing={1}>
            <Text>Общая сумма покупки</Text>
            <Skeleton maxW="200px" isLoaded={!isLoading}>
              <Heading fontSize="8xl">
                {formatPrice((data?.amount ?? 1) / 100, 0,)}
              </Heading>
            </Skeleton>
            <Text color="secondary">
              Включая комиссию
              {" "}
              <Skeleton as="span" display="inline" isLoaded={!commission.isLoading}>
                {formatPrice(commissionAmount, 0,)}
              </Skeleton>
            </Text>
          </Stack>
        </Box>
        <Box>
          <SkeletonCircle isLoaded={!isLoading} boxSize={12}>
            {data?.smallIconLink && <Image objectFit="contain" src={data.smallIconLink} boxSize={12} borderRadius="full" />}
            {!data?.smallIconLink && <CircleImagePlaceholder />}
          </SkeletonCircle>
        </Box>
      </Flex>
    </Box>
  );
}
