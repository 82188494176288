import { Box, Center, Flex, Icon, } from "@chakra-ui/react";
import { XMarkIcon, } from "@heroicons/react/24/outline";
import { ReactNode, } from "react";
import { noop, } from "../../../../utils";

export interface IAppModalProps {
  /** child as a function for modal content. See doc below */
  content?: (onClose: () => void, block: (state: boolean) => void, isBlocked: boolean) => ReactNode;
  /** modal header. Displayed above the content */
  header?: string;
  onClose?: () => void;
  /** children can use this callback to prevent modal from being closed by the backdrop */
  block?: (state: boolean) => void;
  /** if true - the modal window is blocked and can't be closed from backdrop or API */
  isBlocked?: boolean;
}

/**
 * Application modal wrapper.
 * It renders content of every modal window in the app.
 *
 * To show modal use useAppModal hook.
 *
 * @see useAppModal
 */
export function AppModal({ content, header, onClose, block, isBlocked, }: IAppModalProps,) {
  return (
    <Flex
      bg="white"
      borderTopRadius="xl"
      px={4}
      paddingBottom={9}
      position="relative"
      flexDir="column"
    >
      <Icon
        onClick={onClose}
        as={XMarkIcon}
        boxSize={6}
        top={5}
        right={5}
        position="absolute"
        cursor="pointer"
      />
      <Box py={6}>
        { header && (
          <Center>
            {header}
          </Center>
        ) }
      </Box>
      {content?.(onClose ?? noop, block ?? noop, isBlocked ?? false,)}
    </Flex>
  );
}
