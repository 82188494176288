import { Box, Button, Flex, Icon, Text, } from "@chakra-ui/react";
import { Link as RouterLink, useParams, } from "react-router-dom";
import { RoutesMap, } from "../../../../routes";
import { ChevronLeftIcon, } from "@heroicons/react/24/solid";
import { formatDate, } from "../../../../utils/formatDate";
import { useGetOperationFromCache, } from "../../hooks";

export function OperationPageHeader() {
  const operationId = parseInt(useParams().id!,);
  const { data, } = useGetOperationFromCache({ operationId, },);

  return (
    <Box>
      <Flex alignItems="center">
        <Button as={RouterLink} to={RoutesMap.index} variant="circle">
          <Icon as={ChevronLeftIcon} boxSize={6} />
        </Button>
        <Box flex="1">
          {!!data && (
            <Text fontSize="sm" align="center">{data.merchantName}</Text>
          )}
          {!!data && (
            <Text fontSize="sm" align="center" color="secondary">
              {formatDate(new Date(data.createDate,), { year: "numeric", },)}
            </Text>
          )}
        </Box>
        <Box minW={9} />
      </Flex>
    </Box>
  );
}
