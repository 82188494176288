import { Button, FormControl, FormErrorMessage, FormLabel, Spinner, Stack, } from "@chakra-ui/react";
import { usePhoneFormSateReaction, usePhoneFormState, } from "./hooks";
import { EPhoneFormState, } from "../../../../store/slices/phoneFormSliceCreator";
import { PhoneNumberInput, } from "../../../shared/PhoneNumberInput";

/**
 * Component for handling phone input from user
 * * Setup phone logic reactions.
 * * Encapsulate view Logic of phone form
 */
export function PhoneNumberForm() {
  const phoneForm = usePhoneFormState();
  usePhoneFormSateReaction();

  /**
   * Handles the change event for a phone input field.
   * Clearest errorMessage of the form to hide validation
   * errors while user writing.
   */
  const handleChange = (value: string,): void => {
    if (phoneForm.errorMessage) {
      phoneForm.setErrorMessage("",);
    }
    phoneForm.setPhone(value,);
  };

  const handleSubmit = () => {
    if (phoneForm.formState === EPhoneFormState.initial) {
      phoneForm.setFormState(EPhoneFormState.validating,);
    }
  };

  return (
    <Stack spacing={4}>
      <FormControl isInvalid={!!phoneForm.errorMessage}>
        <FormLabel>
          Ваш телефон
        </FormLabel>
        <PhoneNumberInput value={phoneForm.phone} onChange={handleChange} />
        <FormErrorMessage>{phoneForm.errorMessage}</FormErrorMessage>
      </FormControl>
      <Button colorScheme="brand" onClick={handleSubmit}>
        {phoneForm.formState === EPhoneFormState.initial
          ? "Получить SMS"
          : (
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="white"
            />
            )}
      </Button>
    </Stack>
  );
}
