import { useQuery, } from "@tanstack/react-query";
import { cardsQueryConfig, } from "../../../api";
import { useCardsStore, } from "../../../store/cardsStore.ts";
import { Stack, Text, } from "@chakra-ui/react";
import { DebitCardLabel, } from "../../shared/DebitCardLabel";
import { useAppModal, } from "../Modals";
import { CardListModal, } from "../Modals/CardListModal";

export interface IPaymentCardPickerProps {
  /** On change label click. If not provided will open default card picker modal */
  onClick?: () => void;
}

/**
 * This component is responsible for displaying and changing the selected card.
 */
export function PaymentCardPicker({ onClick, }: IPaymentCardPickerProps,) {
  const cardsQuery = useQuery(cardsQueryConfig(),);
  const selectedCardId = useCardsStore(state => state.selectedCardId,);
  const { showModal, } = useAppModal();

  const selectedCard = cardsQuery
    .data
    ?.find(card => card.paymentInstrumentId === selectedCardId,);
  const cardNotAssigned = !selectedCard;

  const handleClick = () => {
    if (!onClick) {
      showModal({
        content: () => <CardListModal />,
        header: "Ваши карты",
      },);
    } else {
      onClick();
    }
  };

  return (
    <Stack spacing={2}>
      <Text size="sm" color="secondary">Списывается с карты</Text>
      <DebitCardLabel
        isLoading={cardsQuery.isLoading}
        onClick={handleClick}
        text={cardNotAssigned
          ? "Карта не добавлена"
          : selectedCard.cardBrand + " *" + selectedCard.cardNumber.slice(-4,)}
        variant={cardNotAssigned ? "error" : "default"}
      />
    </Stack>
  );
}
