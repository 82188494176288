import { AbsoluteCenter, Box, Heading, Stack, Text, Link, } from "@chakra-ui/react";
import { PhoneNumberForm, } from "../../components/features/LoginPage";
import { useLoginFormStore, } from "../../store/loginFormStore";
import { EPhoneFormState, } from "../../store/slices/phoneFormSliceCreator";
import { usePersistStore, } from "../../store/persistStore";
import { EJWTStatus, validateJWT, } from "../../utils";
import { redirect, } from "react-router-dom";
import { RoutesMap, } from "../../routes.tsx";

export function LoginPage() {
  return (
    <Box position="relative" h="calc(100vh - 84px * 2)">
      <AbsoluteCenter axis="both">
        <Stack spacing={9} minW="300px">
          <div><Heading textAlign="center">Вход в кабинет</Heading></div>
          <PhoneNumberForm />
          <Text size="sm" color="gray.500" align="center">
            Продолжая я соглашаюсь с&nbsp;
            <Link
              textDecoration="underline"
              color="gray.500"
              href="https://front.denumtech.ru/assets/Polzovatelskoe_soglashenie_denum.pdf"
            >
              пользовательским соглашением
            </Link>
            {" "}
            и&nbsp;
            <Link
              textDecoration="underline"
              color="gray.500"
              href="https://front.denumtech.ru/assets/Politika_confidencialnosti_denum.pdf"
            >
              политикой конфиденциальности
            </Link>
          </Text>
        </Stack>
      </AbsoluteCenter>
    </Box>
  );
}

LoginPage.loader = () => {
  const state = useLoginFormStore.getState();
  const { deviceJWT, } = usePersistStore.getState();
  const deviceJwtState = validateJWT(deviceJWT,);

  switch (deviceJwtState) {
    case EJWTStatus.Valid: {
      return redirect("/",);
    }
    case EJWTStatus.Expired:
    case EJWTStatus.RefreshRequired: {
      return redirect(RoutesMap.login.pin,);
    }
  }

  state.setPhoneFormState(EPhoneFormState.initial,);
  return null;
};
