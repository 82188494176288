import { useQuery, } from "@tanstack/react-query";
import { manualQuery, repaymentSchedulesByIdQueryConfig, } from "../../../api";

/**
 * This is the temporary solution and it wil be replaced after the schedules endpoint will be ready.
 */
export function useGetScheduleFromCache({
  operationId,
  paymentNumbers,
}: { operationId: number; paymentNumbers: number[] },) {
  return useQuery({
    ...manualQuery(repaymentSchedulesByIdQueryConfig(operationId,),),
    select: data =>
      data.filter(sch => paymentNumbers.includes(sch.number,),),
  },);
}
