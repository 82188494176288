import { Link, } from "@chakra-ui/react";
import { Link as RouterLink, } from "react-router-dom";
import { RoutesMap, } from "../../../../routes";
import { PlaceholderCard, } from "../../../shared/PlaceholderCard";

/**
 * Placeholder for the case when there are no active installments.
 */
export function OperationsEmptyBlock() {
  return (
    <PlaceholderCard
      size="md"
      description="Нет активных покупок"
      action={(
        <Link as={RouterLink} to={RoutesMap.howto}>
          Где купить через Denum?
        </Link>
      )}
    />
  );
}
