import { useState, } from "react";
import { useEffectOnce, } from "../../../utils/hooks";

/**
 * Returns true when component is mounted successfully
 */
export function useIsMounted() {
  const [isMounted, setIsMounted,] = useState(false,);

  useEffectOnce(() => {
    setIsMounted(true,);
    return () => {
      setIsMounted(false,);
    };
  },);

  return isMounted;
}
