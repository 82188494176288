import { space, } from "./space";

/**
 * Sizes for components. Actually a placeholder used only to propagate spaces to sizes.
 */
export const sizes = {
  sizes: {
    ...space.space,
    container: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
    },
  },
};
