import { StateCreator, } from "zustand";

export interface IJWTSliceState {
  jwt?: string;
  setJwt: (jwt: string) => void;
  clearJwt: () => void;
}

/**
 * Contains information about jwt token
 *
 * Already integrated into RunQueryFor as a token provider
 * for every api request
 *
 * @see RunQueryFor
 */
export const JwtSliceCreator: StateCreator<IJWTSliceState> = set => ({
  setJwt: (jwt,) => {
    set(() => ({ jwt, }),);
  },
  clearJwt: () => {
    set(({ jwt, ...rest },) => rest, true,);
  },
});
