import { PaymentModal, useAppModal, } from "../../components/features/Modals";
import { useEffect, useRef, } from "react";
import { LoaderFunctionArgs, redirect, useNavigate, useParams, useSearchParams, } from "react-router-dom";
import { useGetScheduleFromCache, } from "../../components/features/hooks";
import { PageLoader, } from "../../components/shared/PageLoader";
import { useEffectOnce, } from "../../utils/hooks";
import { RoutesMap, } from "../../routes";
import { prepareUnleashClient, unleashClient, } from "../../unleash";

/**
 * Name of a URL search params that is responsible for passing payment numbers to the modal.
 * Arrays are supported by splitting values with a comma.
 *
 * @example
 * /payment?ns=14754,14755,14756&opid=1
 */
export const PAYMENT_NUMBER_PARAM_NAME = "ns";
export const PAYMENT_OPERATION_ID_PARAM_NAME = "opid";

export function PaymentPage() {
  const { showModal, isModalOpen, hideModal, } = useAppModal();
  const params = useParams();
  const navigate = useNavigate();
  // The page could be unmounted only by router.
  // In this case, only if user pressed the back button.
  // In this case, we should prevent back navigation by the navigate function in onClose handler.
  // This can't be done via state, because the state will be lost after the page is unmounted.
  const byUnmount = useRef(false,);

  const [sp,] = useSearchParams();
  const operationId
    = sp.get(PAYMENT_OPERATION_ID_PARAM_NAME,)! || params.id!;
  const paymentSchedulesNumbers = sp.get(PAYMENT_NUMBER_PARAM_NAME,)!.split(",",);

  const schedules = useGetScheduleFromCache({
    operationId: parseInt(operationId,),
    paymentNumbers: paymentSchedulesNumbers.map(n => parseInt(n,),),
  },);

  useEffect(() => {
    if (schedules.isSuccess) {
      showModal({
        onClose: () => {
          if (!byUnmount.current) {
            navigate("..", { relative: "path", },);
          }
        },
        header: "Оплата",
        content: (onClose,) => {
          return (
            <PaymentModal
              onClose={onClose}
              operationId={parseInt(operationId,)}
              paymentInfo={schedules.data}
            />
          );
        },
      },);
    }
  }, [schedules.isSuccess,],);

  useEffectOnce(() => {
    return () => {
      byUnmount.current = true;
    };
  },);

  useEffect(() => {
    return () => {
      if (isModalOpen) {
        hideModal();
      }
    };
  }, [isModalOpen,],);

  if (schedules.isLoading) {
    // this will appear when a user is redirected after a new card submission
    return <PageLoader />;
  }

  return null;
}

PaymentPage.loader = async ({ request, params, }: LoaderFunctionArgs,) => {
  await prepareUnleashClient();
  const isPaymentsEnabled = unleashClient.isEnabled("payments.all",);

  if (!isPaymentsEnabled) {
    return redirect(RoutesMap.index,);
  }

  const sp = new URL(request.url,).searchParams;
  if (!sp.has(PAYMENT_NUMBER_PARAM_NAME,)) {
    return redirect(RoutesMap.index,);
  }
  if (!sp.has(PAYMENT_OPERATION_ID_PARAM_NAME,) && !params.id) {
    return redirect(RoutesMap.index,);
  }
  return null;
};
