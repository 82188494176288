import { Stack, Text, } from "@chakra-ui/react";
import { useQuery, } from "@tanstack/react-query";
import { cardsQueryConfig, } from "../../../api";
import { DebitCardLabel, } from "../../shared/DebitCardLabel";
import { useAppModal, } from "../Modals";
import { CardListModal, } from "../Modals/CardListModal";

/**
 * Block that show all information about linked cards in readonly mode.
 * only the last card from the api response will be shown.
 *
 * The last card is the special card.
 * All automated payments will be made from it.
 */
export function PaymentCardsView() {
  const cards = useQuery(cardsQueryConfig(),);

  const lastCard = cards.data?.[cards.data.length - 1];

  const cardNotAssigned = !lastCard;
  const { showModal, } = useAppModal();

  return (
    <Stack spacing={2}>
      <Text size="sm" color="secondary">Списывается с карты</Text>
      <DebitCardLabel
        isLoading={cards.isLoading}
        onClick={() => {
          showModal({
            content: () => <CardListModal readonly />,
            header: "Ваши карты",
          },);
        }}
        text={cardNotAssigned
          ? "Карта не добавлена"
          : lastCard.cardBrand + " *" + lastCard.cardNumber.slice(-4,)}
        variant={cardNotAssigned ? "error" : "default"}
      />
    </Stack>
  );
}
