import { Box, } from "@chakra-ui/react";
import { LoaderFunctionArgs, redirect, } from "react-router-dom";
import { PageLoader, } from "../../components/shared/PageLoader";
import { useQuery, } from "@tanstack/react-query";
import { operationByIdQueryConfig, } from "../../api";
import { PartialPaymentPageHeader, } from "../../components/features/PartialPayment";
import { PartialPaymentForm, } from "../../components/features/PartialPayment/PartialPaymentForm";
import { partialPaymentStoreInitialState, usePartialPaymentStore, } from "../../store/partialPaymentStore";
import { RoutesMap, } from "../../routes";

export function PartialPaymentPage() {
  const operationId = usePartialPaymentStore(state => state.operationId,);
  const operation = useQuery(operationByIdQueryConfig(operationId.toString(),),);

  if (!operation.isSuccess) {
    return <PageLoader />;
  }

  return (
    <Box>
      <PartialPaymentPageHeader />
      <PartialPaymentForm />
    </Box>
  );
}

PartialPaymentPage.loader = ({ params, }: LoaderFunctionArgs,) => {
  if (!params.id) {
    return redirect(RoutesMap.index,);
  }
  usePartialPaymentStore.setState({
    ...partialPaymentStoreInitialState,
    operationId: parseInt(params.id,),
  },);
  return null;
};
