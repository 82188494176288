import { Box, Button, Card, CardBody, Heading, HStack, Icon, Skeleton, Text, } from "@chakra-ui/react";
import { ExclamationCircleIcon, } from "@heroicons/react/24/solid";
import { CheckIcon, } from "@heroicons/react/16/solid";
import { XMarkIcon, } from "@heroicons/react/24/outline";

export enum ECardVariant {
  /** Basic card. Gray colored. Default card for installments */
  Default = "filled",
  /** Elevated card. With shadow, for installments that's ready to be paid */
  Elevated = "elevated",
  /** Green card for successful payments */
  Paid = "success",
}

export interface IFuturePaymentCardProps {
  variant?: ECardVariant;
  /**
   * Red overdue label will be shown if true
   */
  overdue?: boolean;
  /**
   * Show skeleton if true
   */
  isLoading?: boolean;
  /**
   * Payment date
   */
  date?: string;
  /**
   * Payment amount
   */
  amount?: string;
  /**
   * Company name
   */
  company?: string;
  /**
   * Callback for pay button click
   */
  onPayClick?: () => void;
  /**
   * For paid variant only, callback for close button click
   */
  onCloseClick?: () => void;
}

/**
 * Cards that shows an amount of future payment
 */
export function FuturePaymentCard({
  variant = ECardVariant.Default,
  overdue,
  isLoading,
  amount,
  company,
  date = "",
  onPayClick,
  onCloseClick,
}: IFuturePaymentCardProps,) {
  return (
    <Skeleton borderRadius="lg" isLoaded={!isLoading}>
      <Card
        height="154px"
        width="154px"
        borderRadius="md"
        variant={variant}
        position="relative"
      >
        <CardBody p={2}>
          {variant === ECardVariant.Paid && (
            <Icon
              as={XMarkIcon}
              color="white"
              boxSize={6}
              onClick={onCloseClick}
              position="absolute"
              right="2"
              top="2"
            />
          )}
          <Box
            height={variant === ECardVariant.Default ? "100%" : ""}
            display="flex"
            flexDir="column"
            justifyContent="space-between"
            px={2}
            pb={variant === ECardVariant.Default ? 2 : 0}
          >
            {!overdue && (
              <Text fontSize="sm" mb={4}>
                {date}
              </Text>
            )}
            {overdue && (
              <HStack spacing={1} mb={4}>
                <Icon boxSize={4} as={ExclamationCircleIcon} color="red.500" />
                <Text color="red.500" fontSize="sm">
                  Просрочен
                </Text>
              </HStack>
            )}
            <Box mb={variant !== ECardVariant.Default ? 3 : 0}>
              <Heading>
                {amount}
              </Heading>
              <Text fontSize="sm">{company}</Text>
            </Box>
          </Box>
          <Box>
            {variant === ECardVariant.Elevated
            && (
              <Button onClick={onPayClick} width="100%" colorScheme="brand" size="sm">
                Оплатить
              </Button>
            )}
            {variant === ECardVariant.Paid && (
              <Box
                width="100%"
                bg="whiteAlpha.300"
                height="36px"
                alignItems="center"
                display="flex"
                justifyContent="center"
                borderRadius="md"
              >
                <HStack spacing={1}>
                  <Icon as={CheckIcon} color="white" />
                  <Text fontSize="sm" color="white">Оплачен</Text>
                </HStack>
              </Box>
            )}
          </Box>
        </CardBody>
      </Card>
    </Skeleton>
  );
}
