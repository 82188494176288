import { useLoginFormStore, } from "../../../../../store/loginFormStore";

export function usePhoneFormState() {
  const phone = useLoginFormStore(state => state.phoneNumber,);
  const setPhone = useLoginFormStore(state => state.setPhoneNumber,);
  const formState = useLoginFormStore(state => state.phoneFormState,);
  const setFormState = useLoginFormStore(state => state.setPhoneFormState,);
  const errorMessage = useLoginFormStore(state => state.errorMessage,);
  const setErrorMessage = useLoginFormStore(state => state.setErrorMessage,);
  const getEscapedPhoneNumber = useLoginFormStore(state => state.getEscapedPhoneNumber,);

  return {
    phone,
    setPhone,
    formState,
    setFormState,
    errorMessage,
    setErrorMessage,
    getEscapedPhoneNumber,
  };
}
