import { useEffect, } from "react";
import { EPartialPaymentState, usePartialPaymentStore, } from "../../../../../store/partialPaymentStore";
import { useQuery, } from "@tanstack/react-query";
import { operationByIdQueryConfig, } from "../../../../../api";
import { usePaymentMutation, } from "../../../hooks";
import { useNavigateToSuccessPaymentPage, } from "../../hooks";

const PARTIAL_PAYMENT_ERROR_MESSAGES = {
  EMPTY: "Введите сумму",
  TOO_MUCH: "Это слишком много, нам столько не нужно",
};

export function usePartialPaymentFormReaction() {
  const operationId = usePartialPaymentStore(state => state.operationId,);
  const getParsedAmount = usePartialPaymentStore(state => state.getParsedAmount,);
  const formState = usePartialPaymentStore(state => state.state,);
  const setFormState = usePartialPaymentStore(state => state.setState,);
  const setError = usePartialPaymentStore(state => state.setErrorMsg,);
  const reset = usePartialPaymentStore(state => state.reset,);

  const operation = useQuery(operationByIdQueryConfig(operationId.toString(),),);

  const { mutation: paymentMutation, } = usePaymentMutation({ operationId: operationId, },);

  const navigateToSuccessPage = useNavigateToSuccessPaymentPage();

  useEffect(() => {
    switch (formState) {
      case EPartialPaymentState.Validating: {
        const value = getParsedAmount();
        if (!value) {
          setFormState(EPartialPaymentState.Initial,);
          setError(PARTIAL_PAYMENT_ERROR_MESSAGES.EMPTY,);
          return;
        }
        if (value > operation.data!.operation.remainingAmount / 100) {
          setFormState(EPartialPaymentState.Initial,);
          setError(PARTIAL_PAYMENT_ERROR_MESSAGES.TOO_MUCH,);
          return;
        }
        setFormState(EPartialPaymentState.Validated,);
        return;
      }
      case EPartialPaymentState.Validated: {
        paymentMutation.mutate({ amount: getParsedAmount(), }, {
          onSuccess: () => {
            setFormState(EPartialPaymentState.Submitted,);
          },
          onError: () => {
            setFormState(EPartialPaymentState.Initial,);
          }, },);
        setFormState(EPartialPaymentState.Submitting,);
        return;
      }
      case EPartialPaymentState.Submitted: {
        reset();
        navigateToSuccessPage(getParsedAmount(), operationId,);
      }
    }
  }, [formState,],);
}
