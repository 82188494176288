/**
 * Base chakra colors rewritten.
 * Here we are trying to follow chakra mental model of colors palette.
 * Unfortunately in initial design colors are slightly different and not ranged
 * properly. To merge our and chakra styled system we used these variants.
 * 500 - of every color is an initial design color from figma and
 * rest of them are made by https://themera.vercel.app/ to save a persistent
 * color model.
 * Every components hover, focus and other colors are related to this model.
 * ! Values for hover/focus wasn't initially present in design so colors from
 * themera was picked.
 * ! BrandBlack colors is exception, and they should only be used for brandBlack button variant.
 */
export const colors = {
  colors: {
    // only for black button.
    brandBlack: {
      300: "#2e2d2f",
      400: "#29282a",
      500: "#282729",
    },
    brand: {
      50: "#F1E7FE",
      100: "#D7BBFC",
      200: "#BE8FF9",
      300: "#A463F7",
      400: "#8B38F5",
      500: "#7D20F4",
      600: "#5B0AC2",
      700: "#440792",
      800: "#2D0561",
      900: "#170231",
    },
    gray: {
      50: "#F3F3F3",
      100: "#DDDDDD",
      200: "#C4C4C4",
      300: "#ADADAD",
      400: "#969696",
      500: "#808080",
      600: "#666666",
      700: "#4D4D4D",
      800: "#333333",
      900: "#1A1A1A",
    },
    red: {
      50: "#FEE7E7",
      100: "#FBBCBC",
      200: "#F89090",
      300: "#F66565",
      400: "#F33A3A",
      500: "#F44545",
      600: "#C10B0B",
      700: "#900909",
      800: "#600606",
      900: "#300303",
    },
  },
};
