import { parseJwt, } from "./parseJWT";

export enum EJWTStatus {
  /** Token is valid */
  Valid,
  /** Token is completely invalid (missing or can't be red) */
  Invalid,
  /** Token is about to expire (10 minutes left) */
  RefreshRequired,
  /** Token is expired (it's valid JWT string but expired) */
  Expired,
}

export function validateJWT(jwt = "",): EJWTStatus {
  if (!jwt) {
    return EJWTStatus.Invalid;
  }

  try {
    const jwtPayload = parseJwt(jwt,);

    const now = Math.ceil(Date.now() / 1000,);
    const tenMinutesAfterNow = now + (60 * 10);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (jwtPayload.exp === undefined) {
      // defensive code
      console.warn("JWT payload doesn't have 'exp' field",);
      return EJWTStatus.Invalid;
    }

    // token expired
    if (jwtPayload.exp < now) {
      return EJWTStatus.Expired;
    }

    // token will be expired after ten minutes. Refreshing.
    if (jwtPayload.exp < tenMinutesAfterNow) {
      return EJWTStatus.RefreshRequired;
    }

    return EJWTStatus.Valid;
  } catch (_e) {
    return EJWTStatus.Invalid;
  }
}
