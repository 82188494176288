import { createMultiStyleConfigHelpers, } from "@chakra-ui/react";
import { cardAnatomy, } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig, }
  = createMultiStyleConfigHelpers(cardAnatomy.keys,);

const elevated = definePartsStyle({
  container: {
    shadow: "2px 4px 24px -8px #00000026",
    zIndex: "2", // for shadow elevation
  },
},);

const filled = definePartsStyle({
  container: {
    bg: "gray.50",
    _dark: {
      bg: "gray.500",
    },
  },
},);

const success = definePartsStyle({
  container: {
    bg: "green.400",
    _dark: {
      bg: "green.500",
    },
    color: "white",
  },
},);

export const Card = defineMultiStyleConfig({ variants: { elevated, filled, success, }, },);
