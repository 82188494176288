import { extendTheme, } from "@chakra-ui/react";
import { borderRadius, colors, fonts, sizes, space, } from "./base";
import { Button, Card, FormLabel, Input, Link, PinInput, Skeleton, } from "./components";

/**
 * Main theme of the application.
 * Globals and Semantic left in here only for obscurity of theme.
 * If you need to introduce new style you should create a different file for that.
 * @see https://chakra-ui.com/docs/styled-system/theme - for more information
 */
export const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        fontWeight: "normal",
      },
    },
  },
  semanticTokens: {
    colors: {
      error: "red.500",
      secondary: "gray.500",
      success: "green.400",
    },
  },
  components: {
    FormLabel,
    Input,
    Button,
    PinInput,
    Skeleton,
    Link,
    Card,
  },
  ...colors,
  ...fonts,
  ...borderRadius,
  ...space,
  ...sizes,
},);
