import { theme, } from "./theme/theme";
import { ChakraProvider, ToastProviderProps, } from "@chakra-ui/react";
import { QueryClientProvider, } from "@tanstack/react-query";
import { RouterProvider, } from "react-router-dom";
import { routes, } from "./routes";
import { queryClient, } from "./api";
import React from "react";
import { Toast, } from "./components/shared/Toast";
import { FlagProvider, } from "@unleash/proxy-client-react";
import { unleashClient, } from "./unleash";

const defaultToastOptions: ToastProviderProps = {
  defaultOptions: {
    isClosable: true,
    duration: 5000,
    position: "bottom",
    colorScheme: "black",
    render(props,): React.ReactNode {
      return (
        <Toast icon={props.icon} message={props.description} onClose={props.onClose} />
      );
    },
  },
};

const router = routes();

function App() {
  return (
    <ChakraProvider theme={theme} toastOptions={defaultToastOptions}>
      <FlagProvider unleashClient={unleashClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </FlagProvider>
    </ChakraProvider>
  );
}

export default App;
