import { VITE_API_URL, } from "../ENV";

const withHost = (path: string,) => `${VITE_API_URL}${path}`;
const withParams = (path: string, params: Record<string, string | number | null | boolean>,) => {
  return Object.keys(params,).reduce((a, v, idx,) =>
    !["null", "undefined",].includes(typeof params[v],)
      ? `${a}${idx > 0 ? "&" : ""}${v}=${encodeURIComponent(params[v] as string,)}`
      : a,
  `${path}?`,);
};

const defaultHeaders = {
  headers: {
    "Content-Type": "application/json",
  },
};

/**
 * Provides a convenient way to build API URL and requests.
 *
 * queries - for GET requests.
 * mutations - for POST, PUT, DELETE requests.
 */
export const ApiBuilder = {
  queries: {
    cards: {
      /**
       * Обновление алиаса карты
       */
      index: {
        url: withHost("/v1/cards",),
      },
      binding: {
        /** Получение ссылки для привязки карты */
        link: {
          url: withHost("/v1/cards/binding/link",),
        },
        /** Получить статус привязки карты */
        status: {
          url: withHost("/v1/cards/binding/status",),
        },
      },
    },
    documents: {
      byAlias: (alias: string,) => ({
        /** Получение ссылки на документ */
        url: {
          url: withHost(`/v1/documents/${alias}/url`,),
        },
      }),
      /** Получение типа документов */
      types: {
        url: withHost("/v1/documents/types",),
      },
    },
    /** Получение списка макрокатегорий */
    macrocategories: {
      url: withHost("/v1/macrocategories",),
    },
    /** Получение списка гражданств */
    nationalities: {
      url: withHost("/v1/nationalities",),
    },
    offers: {
      /** Получение предложений по рассрочке. */
      index: {
        url: withHost("/v1/offers",),
      },
      /** Получение планов платежей */
      repaymentSchedules: {
        url: withHost("/v1/offers/repayment-schedules",),
      },
    },
    operations: {
      /** Получение списка операций клиента */
      index: (params: { startDate: string; endDate: string; perPage: number; page: number },) => ({
        url: withHost(withParams("/v1/operations", params,),),
      }),
      byId: (id: string,) => ({
        /** Получение детальной информации по операции клиента */
        index: {
          url: withHost(`/v1/operations/${id}`,),
        },
        /** Получение комиссии по операции */
        commission: {
          url: withHost(`/v1/operations/${id}/commission`,),
        },
        /** Получение товарной корзины по операции клиента */
        products: {
          url: withHost(`/v1/operations/${id}/products`,),
        },
        /** Получение графика погашений (платежей) по операции клиента */
        repaymentSchedule: {
          url: withHost(`/v1/operations/${id}/repayment-schedule`,),
        },
      }),
      /** Получение графика платежей по всем операциям */
      repaymentSchedule: {
        url: withHost("/v1/operations/repayment-schedule",),
      },
      /** Получение лимита и остатков по нему для клиента */
      limits: {
        url: withHost("/v1/operations/limits",),
      },
      /** Получение трат и макрокатегорий по операциям клиента */
      macrocategories: {
        url: withHost("/v1/operations/macrocategories",),
      },
    },
    partners: {
      /** Поиск партнеров */
      index: {
        url: withHost("/v1/partners",),
      },
      byId: (id: string,) => ({
        /** Получение детальной информации по партнеру */
        details: {
          url: withHost(`/v1/partners/${id}/details`,),
        },
      }),
    },
    paymentRequests: {
      /** Проверка статуса выдачи заявки, созданной по QR */
      checking: {
        url: withHost("/v1/payment-requests/checking",),
      },
    },
    payments: {
      byPaymentId: (paymentId: string,) => ({
        /** Проверка статуса платежа */
        status: {
          url: withHost(`/v1/payments/${paymentId}/status`,),
        },
      }),
    },
    /** Получение информации о пользователе */
    userProfiles: {
      url: withHost("/v1/user-profiles",),
    },
  },
  mutations: {
    devices: (pin: string, fingerprint: string,) => ({
      ...defaultHeaders,
      url: withHost("/v1/devices",),
      method: "POST",
      body: { pincode: pin, fingerprint, },
    }),
    authenticateDevice: (pin: string, fingerprint: string,) => ({
      ...defaultHeaders,
      url: withHost("/v1/devices/authenticate",),
      method: "POST",
      body: { pincode: pin, fingerprint, },
    }),
    /** Создание обращения пользователя */
    appeals: (text: string,) => ({
      ...defaultHeaders,
      url: withHost("/v1/appeals",),
      method: "POST",
      body: { text, },
    }),
    cards: {
      /** Обновление алиаса карты */
      byId: (id: string, aliasName: string,) => ({
        ...defaultHeaders,
        url: withHost(`/v1/cards/${id}`,),
        method: "PUT",
        body: { aliasName, },
      }),
    },
    confirmationCode: {
      /** Проверка кода подтверждения */
      checking: (code: string,) => ({
        ...defaultHeaders,
        url: withHost("/v1/confirmation-code/checking",),
        method: "POST",
        body: { code, },
      }),
      /** Переотправка кода подтверждения */
      resending: () => ({
        ...defaultHeaders,
        url: withHost("/v1/confirmation-code/resending",),
        method: "POST",
        body: {},
      }),
    },
    offers: {
      byId: (id: string,) => ({
        /** Подтверждение выбора предложения. */
        confirmation: () => ({
          ...defaultHeaders,
          url: withHost(`/v1/offers/${id}/confirmation`,),
          method: "POST",
          body: {},
        }),
      }),
    },
    operations: {
      byId: (operationId: string,) => ({
        /** Отправка платежа */
        payments: (body: { paymentType: string; amount: number; paymentInstrumentId: number },) => ({
          ...defaultHeaders,
          url: withHost(`/v1/operations/${operationId}/payments`,),
          method: "POST",
          body,
        }),
      }),
    },
    otp: {
      /** Отправка смс кода */
      index: (phone: string,) => ({
        ...defaultHeaders,
        url: withHost("/v1/otp",),
        method: "POST",
        body: { phone, },
      }),
      /** Проверка смс кода */
      verifications: (phone: string, code: string,) => ({
        ...defaultHeaders,
        url: withHost("/v1/otp/verifications",),
        method: "POST",
        body: { phone, code, },
      }),
    },
    /** Создание заявки на оплату по QR коду */
    paymentRequests: (partnerId: string, pointCode: string,) => ({
      ...defaultHeaders,
      url: withHost("/v1/payment-requests",),
      method: "POST",
      body: {
        partnerId,
        pointCode,
      },
    }),
    token: {
      /** Обновление токена */
      refreshment: () => ({
        ...defaultHeaders,
        url: withHost("/v1/token/refreshment",),
        method: "POST",
      }),
    },
  },
};
