import { create, } from "zustand";
import { devtools, } from "zustand/middleware";

export interface ICardsStareState {
  selectedCardId: number;
  selectCard: (id: number) => void;
}

export const useCardsStore = create<ICardsStareState>()(
  devtools(set => ({
    selectedCardId: 0,
    selectCard: (id: number,) => { set({ selectedCardId: id, },); },
  }),),
);
