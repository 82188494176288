import { Icon, } from "@chakra-ui/react";
import { CheckCircleIcon, } from "@heroicons/react/24/solid";
import { PlaceholderCard, } from "../../../shared/PlaceholderCard";

export interface IFuturePaymentsEmptyBlockProps {
  /**
   * When true, the block will show a message that all payments are paid.
   */
  active?: boolean;
}

/**
 * Placeholder for future payments block.
 */
export function FuturePaymentsEmptyBlock({ active, }: IFuturePaymentsEmptyBlockProps,) {
  if (active) {
    return (
      <PlaceholderCard
        size="lg"
        icon={<Icon mb={2} color="success" as={CheckCircleIcon} boxSize={6} />}
        title="У вас все оплачено"
      />
    );
  } else {
    return (
      <PlaceholderCard
        size="lg"
        title="У вас пока нет покупок"
      />
    );
  }
}
