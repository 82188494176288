import { Box, Icon, } from "@chakra-ui/react";
import { ShoppingBagIcon, } from "@heroicons/react/24/solid";
import { ChakraProps, } from "@chakra-ui/system";

/**
 * This component is a placeholder for the circle image.
 * This can be used as a default placeholder for partners logos
 *
 * Props are inherited from the Box component.
 *
 * @see Box
 */
export function CircleImagePlaceholder(props: ChakraProps,) {
  return (
    <Box
      w={12}
      h={12}
      borderRadius="full"
      bg="blackAlpha.300"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Icon color="gray.500" as={ShoppingBagIcon} boxSize={6} />
    </Box>
  );
}
