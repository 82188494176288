import { Operation, } from "../MainPage/Operations/Operation.tsx";
import { generatePath, Link, } from "react-router-dom";
import { RoutesMap, } from "../../../routes.tsx";
import { formatDate, } from "../../../utils/formatDate.ts";
import { formatTermUnit, } from "../../../utils/formatTermUnit.ts";
import { OperationsEmptyBlock, } from "../MainPage/Operations/OperationsEmptyBlock.tsx";
import { useQuery, } from "@tanstack/react-query";
import { operationsQueryConfig, } from "../../../api";
import { Box, Flex, Stack, Text, } from "@chakra-ui/react";
import { useOperationListFilters, } from "../../../store/operationListFilters.ts";

export interface IOperationsListProps {
  /**
   * * default - this the default variant with a link to the "how to page"
   * * tiny - this is the variant for list usage
   */
  emptyBlockVariant?: "default" | "tiny";
}

/**
 * Operation is installment or purchase in terms of application logic.
 *
 * This block is responsible for displaying the list of all instalments,
 * skeletons, and placeholders.
 */
export function OperationsList({ emptyBlockVariant = "default", }: IOperationsListProps,) {
  // that will trigger rerendering of the component when the state changes
  // we don't need to use the state itself, so we can just call the hook
  useOperationListFilters(state => state.state,);
  // this how we will get our filter flags
  const getFilterFlags = useOperationListFilters(state => state.getStateFlags,);
  const operations = useQuery(operationsQueryConfig(),);

  const validOperations = operations
    .data
    ?.data
    .filter(operation => getFilterFlags().includes(operation.operationStatus,),)
    .sort(a => a.operationStatus === 3 ? -1 : 1,)
    ?? [];

  return (
    <Stack spacing={2}>
      {operations.isLoading && new Array(3,).fill(0,).map((_, i,) => (
        <Operation key={i} isLoading />
      ),)}
      {operations.isSuccess && validOperations.length > 0 && validOperations
        .map(operation => (
          <Link
            key={operation.id}
            to={generatePath(RoutesMap.operations.operation, { id: operation.id.toString(), },)}
          >
            <Operation
              logoSrc={operation.smallIconLink}
              paymentDate={formatDate(new Date(operation.dateNextPayment,),)}
              termDate={operation.termInfo.value + " " + formatTermUnit(operation.termInfo.unit,)}
              amount={operation.amount / 100}
              companyName={operation.merchantName}
            />
          </Link>
        ),)}
      {operations.isSuccess && validOperations.length === 0 && (
        <Box>
          {emptyBlockVariant === "default" && <OperationsEmptyBlock />}
          {emptyBlockVariant === "tiny" && (
            <Flex direction="column" alignItems="center" py={20}>
              <Text>Нет завершенных покупок</Text>
            </Flex>
          )}
        </Box>
      )}
    </Stack>
  );
}
