import { defineStyle, defineStyleConfig, } from "@chakra-ui/react";

/**
 * Rewrites gray colorScheme to be more compatible with the Figma.
 * Introduce brandBlack colorScheme for black buttons.
 */
const solid = defineStyle((props,) => {
  const { colorScheme, } = props;
  if (colorScheme === "gray") {
    return {
      bg: "gray.50",
      color: "black",
      _hover: {
        bg: "gray.100",
        _disabled: {
          bg: "gray.100",
        },
      },
      _active: { bg: "gray.200", },
    };
  }

  if (colorScheme === "black") {
    return {
      bg: "brandBlack.500",
      color: "white",
      _hover: {
        bg: "brandBlack.400",
      },
      _active: { bg: "brandBlack.300", },
    };
  }
  return {};
},);

const circle = defineStyle({
  minW: 12,
  w: 12,
  h: 12,
  borderRadius: "full",
  bg: "gray.50",
  p: 0,
},);

export const Button = defineStyleConfig({ variants: { solid, circle, }, },);
