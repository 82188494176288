import { Box, Center, Flex, Stack, Text, } from "@chakra-ui/react";
import { ReactNode, } from "react";

export interface IPlaceholderCardProps {
  icon?: ReactNode;
  title?: string;
  description?: string;
  action?: ReactNode;
  size?: "md" | "lg";
}

const sizeMap = {
  md: "120px",
  lg: "154px",
};

/**
 * Default gray informational placeholder
 */
export function PlaceholderCard({ action, title, icon, description, size = "md", }: IPlaceholderCardProps,) {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      bg="gray.50"
      minH={sizeMap[size]}
      position="relative"
      borderRadius="lg"
    >
      <Center>
        <Stack alignItems="center" spacing={1}>
          {icon}
          {title && <Text minW="200px" align="center">{title}</Text>}
          {description && <Text color="secondary">{description}</Text>}
          {action && <Box mt="6px">{action}</Box>}
        </Stack>
      </Center>
    </Flex>
  );
}
