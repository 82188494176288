import { IRepaymentScheduleByIdResponse, } from "../../../../../api";

/**
 * The List should contain three parts: paid, overdue, and upcoming payments.
 *
 * * upcoming - payments with status 1
 * * paid - payments with status 2
 * * overdue - payments with status 3
 *
 * The idea is to split the list of payments into three parts based on the status and then
 * render each part separately.
 *
 * In feed, it will be rendered in this sequence: paid, overdue, upcoming.
 */
export type TPaymentListSplitType = [
  IRepaymentScheduleByIdResponse[], // paid (EPaymentSplit.Paid)
  IRepaymentScheduleByIdResponse[], // overdue (EPaymentSplit.Overdue)
  IRepaymentScheduleByIdResponse[], // fst upcoming (EPaymentSplit.FstUpcoming) - should be elevated
  IRepaymentScheduleByIdResponse[], // upcoming (EPaymentSplit.Upcoming)
];

/**
 * You can use this enum to access the parts of the split list in a more convenient way.
 */
export enum EPaymentSplit {
  Paid = 0,
  Overdue = 1,
  FstUpcoming = 2,
  Upcoming = 3,
}

/**
 * Transform IRepaymentScheduleByIdResponse to the TPaymentListSplitType.
 *
 * To get more information, consider reading description TPaymentListSplitType
 *
 * @see IRepaymentScheduleByIdResponse
 */
export function asPaymentSplit(data: IRepaymentScheduleByIdResponse[],): TPaymentListSplitType {
  const pre = data
    .sort((a, b,) =>
      a.number > b.number ? 1 : -1,)
    .reduce<TPaymentListSplitType>((acc, payment,) => {
      switch (payment.status) {
        case 1: {
          acc[EPaymentSplit.Upcoming].push(payment,);
          return acc;
        }
        case 2: {
          acc[EPaymentSplit.Paid].push(payment,);
          return acc;
        }
        case 3: {
          acc[EPaymentSplit.Overdue].push(payment,);
          return acc;
        }
        default: return acc;
      }
    }, [[], [], [], [],],);

  // There will not be any upcoming payments if everything is paid.
  if (!hasOverduePayment(pre,) && hasUpcomingPayment(pre,)) {
    const [fstUpcoming, ...rest] = pre[EPaymentSplit.Upcoming];

    return [pre[0], pre[1], [fstUpcoming,], rest,] as TPaymentListSplitType;
  }

  return pre;
}

/**
 * Get all unpaid payments from split in described order.
 * * FstUpcoming
 * * Upcoming
 * * Overdue
 */
export function getAllUnpaidPayments(split: TPaymentListSplitType,) {
  return [
    ...split[EPaymentSplit.FstUpcoming],
    ...split[EPaymentSplit.Upcoming],
    ...split[EPaymentSplit.Overdue],
  ];
}

/**
 * Check if there are any overdue payments in the split.
 */
export function hasOverduePayment(split: TPaymentListSplitType,) {
  return split[EPaymentSplit.Overdue].length > 0;
}

/**
 * Check if there are any upcoming payments in the split.
 */
export function hasUpcomingPayment(split: TPaymentListSplitType,) {
  return split[EPaymentSplit.Upcoming].length > 0;
}

/**
 * Check if the payment should be paid.
 */
export function shouldBePaid(splitType: number,) {
  return [EPaymentSplit.FstUpcoming, EPaymentSplit.Overdue,].includes(splitType,);
}
