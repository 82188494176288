import { Image, } from "@chakra-ui/react";
import logo from "../../../assets/logo.svg";

/**
 * Application Logo component
 */
export function Logo() {
  return (
    <div>
      <Image src={logo} />
    </div>
  );
}
