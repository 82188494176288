import { Outlet, useLocation, useNavigate, } from "react-router-dom";
import { Logo, } from "../../components/shared/Logo";
import { Box, Center, Icon, } from "@chakra-ui/react";
import { RoutesMap, } from "../../routes";
import { ChevronLeftIcon, } from "@heroicons/react/24/outline";

export function LoginPageLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackClick = (): void => {
    navigate(RoutesMap.login.index,);
  };

  return (
    <div>
      <Center position="relative">
        <Box py="25px" minW="300px" display="flex" alignItems="center" flexDir="column" position="relative">
          {location.pathname === RoutesMap.login.otp && (
            <Box onClick={handleBackClick} cursor="pointer" position="absolute" top={5} left={0}>
              <Icon as={ChevronLeftIcon} boxSize="6" color="gray.500" />
            </Box>
          )}
          <Logo />
        </Box>
      </Center>
      <Outlet />
    </div>
  );
}

LoginPageLayout.loader = () => null;
