import { useMutation, } from "@tanstack/react-query";
import { useEffect, } from "react";
import { useSMSFormState, } from "./useSMSFormState";
import { ESMSFormState, } from "../../../../../store/slices/smsFormSliceCreator";
import { NetworkError, otpVerificationMutationFn, } from "../../../../../api";
import { useToast, } from "@chakra-ui/react";
import { SMS_FORM_ERROR_MESSAGES, } from "./useSMSFormStateReaction";
import { usePersistStore, } from "../../../../../store/persistStore";

const toastId = "verification-mutation";

/**
 * Uses the OTP verification mutation reaction to verify the user's OTP code.
 */
export function useOTPVerificationMutationReaction() {
  const toast = useToast();
  const smsFormState = useSMSFormState();
  const setJWT = usePersistStore(state => state.setJwt,);

  const status = useMutation({
    mutationFn: otpVerificationMutationFn,
  },);

  useEffect(() => {
    switch (status.status) {
      case "success": {
        smsFormState.setFormState(ESMSFormState.submitted,);
        const response = status.data as { token: string };
        setJWT(response.token,);
        return;
      }
      case "error": {
        const error = status.error as NetworkError;

        switch (error.internalCode) {
          // 1100 - invalid code and 7000 too
          case 7000:
          case 1100: {
            smsFormState.setErrorMessage(SMS_FORM_ERROR_MESSAGES.incorrect,);
            smsFormState.setFormState(ESMSFormState.initial,);
            return;
          }
        }
        // status code is not 456 or unexpected internal code
        toast({
          id: toastId,
          description: SMS_FORM_ERROR_MESSAGES.unexpectedServerError,
        },);
        smsFormState.setFormState(ESMSFormState.initial,);
        return;
      }
    }
  }, [status.status,],);

  return status;
}
