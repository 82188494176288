import { inputAnatomy, } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig, }
  = createMultiStyleConfigHelpers(inputAnatomy.keys,);

/**
 * Introduce brand input variant.
 * Set brand variant as default
 */
export const brand = definePartsStyle({
  field: {
    errorBorderColor: "red.500",
    fontWeight: "normal",
    bg: "white",
    border: "1px solid",
    borderColor: "gray.100",
    _focus: {
      borderColor: "brand.500",
      bg: "white",
    },
    _invalid: {
      borderColor: "red.500",
    },
    _placeholderShown: {
      bg: "gray.50",
    },
  },
},);

export const Input = defineMultiStyleConfig({ variants: { brand, }, defaultProps: { variant: "brand", }, },);
