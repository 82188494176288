import { parsePath, redirect, } from "react-router-dom";

/**
 * Prevents cyclic redirects
 */
export function redirectSafe(path: string, requestUrl = window.location.href,) {
  if (!parsePath(requestUrl,).pathname?.includes(path,)) {
    return redirect(path,);
  }
  return null;
}
