import { defineStyle, defineStyleConfig, } from "@chakra-ui/react";

const brand = defineStyle({
  color: "brand.500",
},);

export const Link = defineStyleConfig({
  variants: { brand, },
  defaultProps: { variant: "brand", },
},);
