export interface IJWT {
  /**
   * Issuer
   */
  iss: string;
  /**
   * Issued at
   */
  iat: number;
  /**
   * Expiration time
   */
  exp: number;
  /**
   * Not valid before
   */
  nbf: number;
  /**
   * JWT ID - unique identifier of this token
   */
  jti: string;
  /**
   * Subject (whom the token refers to)
   */
  sub: string;
  /**
   * ???
   */
  prv: string;
}

/**
 * Function for decoding JWT tokens
 *
 * @see https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
 */
export function parseJwt(token: string,): IJWT {
  const base64Url = token.split(".",)[1];
  const base64 = base64Url.replace(/-/g, "+",).replace(/_/g, "/",);
  const jsonPayload = decodeURIComponent(window.atob(base64,).split("",).map(function (c,) {
    return "%" + ("00" + c.charCodeAt(0,).toString(16,)).slice(-2,);
  },).join("",),);

  return JSON.parse(jsonPayload,) as IJWT;
}
