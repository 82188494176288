// All envs are reexported here to mock them in tests
const {
  VITE_API_URL,
  DEV,
  MODE,
  PROD,
  VITE_APP_VERSION,
  VITE_UNLEASH_URL,
  VITE_UNLEASH_PUB_KEY,
  VITE_UNLEASH_POLL_INTERVAL_SEC,
  VITE_SENTRY_DSN,
  VITE_SENTRY_ENV_NAME,
} = import.meta.env;

export {
  VITE_API_URL,
  VITE_APP_VERSION,
  DEV,
  MODE,
  PROD,
  VITE_UNLEASH_URL,
  VITE_UNLEASH_PUB_KEY,
  VITE_UNLEASH_POLL_INTERVAL_SEC,
  VITE_SENTRY_DSN,
  VITE_SENTRY_ENV_NAME,
};
