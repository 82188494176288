import { create, } from "zustand";
import { IJWTSliceState, JwtSliceCreator, } from "./slices/jwtSliceCreator";
import { devtools, persist, } from "zustand/middleware";
import { IOtpSliceState, otpSliceCreator, } from "./slices/otpSliceCreator";
import { fingerPrintSliceCreator, IFingerprintSliceState, } from "./slices/fingerPrintSliceCreator.ts";

export type IPersistentStoreState = IJWTSliceState & IOtpSliceState & IFingerprintSliceState;

/**
 * Persist storage that will preserve all data after page reloading.
 * Use it only in this case if you need to store some data in the local storage conveniently
 * like JWT token.
 *
 * All state slices are compatible with both storages you can replace them it any time whenever you like
 * @see useAppStore
 */
export const usePersistStore = create<IPersistentStoreState>()(
  devtools(
    persist(
      (...a) => ({
        ...JwtSliceCreator(...a,),
        ...otpSliceCreator(...a,),
        ...fingerPrintSliceCreator(...a,),
      }),
      { name: "persist-store", },
    ),
    { name: "persist-store", },
  ),);
