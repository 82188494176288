import { Link, Outlet, } from "react-router-dom";
import { Box, HStack, Icon, Stack, } from "@chakra-ui/react";
import { Logo, } from "../components/shared/Logo";
import { FuturePaymentsBlock, FuturePaymentsEmptyBlock, OperationsBlock, } from "../components/features/MainPage";
import { HowToBlock, } from "../components/features/HowToPage";
import { operationsQueryConfig, } from "../api";
import { useQuery, } from "@tanstack/react-query";
import { UserIcon, } from "@heroicons/react/24/outline";
import { RoutesMap, } from "../routes.tsx";
import { PaymentCardsView, } from "../components/features/PaymentCardsView";
import { useFlag, } from "@unleash/proxy-client-react";

/**
 * Main page of the application
 */
export function MainPage() {
  // if the response from this hook is empty, we show the empty state.
  // this request is not implemented yet.
  // const paymentsData = useQuery(repaymentSchedulesQueryConfig(),);
  const operations = useQuery(operationsQueryConfig(),);
  const paymentsData = operations.data?.data ?? [];
  const isOperationsHistoryEnabled = useFlag("operations.history",);
  const isPaymentCardsEnabled = useFlag("payments.cards",);
  const isNextPaymentsEnabled = useFlag("payments.next_payments_block",);

  if (operations.isSuccess && paymentsData.length === 0) {
    return (
      <Stack spacing={4} mb={4} pt={2}>
        <Box>
          <HStack>
            <Logo />
            <Box flex="1" />
            <Box>
              <Link to={RoutesMap.profile}>
                <Box
                  w={12}
                  h={12}
                  borderRadius="full"
                  bg="gray.50"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon color="gray.500" as={UserIcon} boxSize={6} />
                </Box>
              </Link>
            </Box>
          </HStack>
        </Box>
        <FuturePaymentsEmptyBlock />
        <HowToBlock />
      </Stack>
    );
  }

  return (
    <Stack id="mainPage" spacing={4} mb={4}>
      <Box py="25px">
        <HStack>
          <Logo />
          <Box flex="1" />
          <Box>
            <Link to={RoutesMap.profile}>
              <Box
                w={12}
                h={12}
                borderRadius="full"
                bg="gray.50"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon color="gray.500" as={UserIcon} boxSize={6} />
              </Box>
            </Link>
          </Box>
        </HStack>
      </Box>
      {isNextPaymentsEnabled && (
        <FuturePaymentsBlock />
      )}
      {isPaymentCardsEnabled && (
        <PaymentCardsView />
      )}
      <Box mb="5" />
      {isOperationsHistoryEnabled && (
        <OperationsBlock />
      )}
      <Outlet />
    </Stack>
  );
}

MainPage.loader = () => null;
