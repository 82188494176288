import { create, } from "zustand";
import { devtools, } from "zustand/middleware";

export enum EPinState {
  Initial,
  Validating,
  Validated,
  Submitting,
  Submitted,
}

export interface IPinFormState {
  state: EPinState;
  pin: string;
  errorMessage: string;
  setState: (newState: EPinState) => void;
  setPin: (pin: string) => void;
  setErrorMessage: (message: string) => void;
  reset: () => void;
}

export const partialPinFormStoreState = {
  state: EPinState.Initial,
  pin: "",
  errorMessage: "",
} satisfies Partial<IPinFormState>;

export const usePinFormStore = create<IPinFormState>()(devtools(set => ({
  ...partialPinFormStoreState,
  setState: (newState,) => { set({ state: newState, },); },
  setPin: (pin,) => { set({ pin, },); },
  setErrorMessage: (message,) => { set({ errorMessage: message, },); },
  reset: () => { set(partialPinFormStoreState,); },
}),),);
