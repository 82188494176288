import { create, } from "zustand";
import { devtools, } from "zustand/middleware";

export enum EFiltersState {
  Active = "active",
  Completed = "completed",
}

export interface IOperationListFiltersState {
  state: EFiltersState;
  setFilters: (state: EFiltersState) => void;
  getStateFlags: () => number[];
  clear: () => void;
}

export const useOperationListFilters = create<IOperationListFiltersState>()(
  devtools((set, get,) => ({
    state: EFiltersState.Active,
    setFilters: (state,) => { set({ state, },); },
    clear: () => { set({ state: EFiltersState.Active, },); },
    getStateFlags: () => {
      switch (get().state) {
        case EFiltersState.Active:
          return [1, 3,];
        case EFiltersState.Completed:
          return [2,];
        default:
          return [];
      }
    },
  }),
  { name: "operation-list-filters", },),
);
