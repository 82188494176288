import { productsQueryConfig, } from "../../../../api";
import { useQuery, } from "@tanstack/react-query";
import { useParams, } from "react-router-dom";
import { Box, Heading, HStack, Skeleton, Stack, Text, } from "@chakra-ui/react";
import { formatPrice, } from "../../../../utils/formatPrice";

/**
 * The list of the products(goods) in the operation.
 */
export function ProductList() {
  const operationId = useParams().id!;
  const productsQuery = useQuery(productsQueryConfig(operationId,),);

  return (
    <Box>
      <Box id="rest" py={4}>
        <Heading fontSize="xl">Товары</Heading>
      </Box>
      <Stack spacing={4}>
        {productsQuery.isSuccess && productsQuery.data.items.map((item, i,) => (
          <HStack key={i}>
            <Box flex="1">{item.name}</Box>
            <Box>
              <Text color="secondary">
                {formatPrice(item.amount / 100,)}
              </Text>
            </Box>
          </HStack>
        ),)}
      </Stack>
      {productsQuery.isLoading && (
        <Stack spacing={4}>
          {new Array(3,).fill(0,).map((_, i,) => (
            <HStack key={i}>
              <Skeleton h={6} w="60%" />
              <Box flex="1" />
              <Skeleton w="20%" h={6} />
            </HStack>
          ),)}
        </Stack>
      )}
    </Box>
  );
}
