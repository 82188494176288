import { create, } from "zustand";
import { devtools, } from "zustand/middleware";
import { formatPrice, } from "../utils/formatPrice";

export enum EPartialPaymentState {
  Initial,
  Validating,
  Validated,
  Submitting,
  Submitted,
}

export interface IPartialPaymentState {
  operationId: number;
  amountStr: string;
  errorMsg: string;
  state: EPartialPaymentState;
  setOperationId: (id: number) => void;
  setAmountStr: (amount: string) => void;
  setState: (state: EPartialPaymentState) => void;
  setErrorMsg: (errorMsg: string) => void;
  getParsedAmount: () => number;
  reset: () => void;
}

export const partialPaymentStoreInitialState = {
  state: EPartialPaymentState.Initial,
  operationId: 0,
  amountStr: "",
  errorMsg: "",
};

export const usePartialPaymentStore = create<IPartialPaymentState>()(
  devtools((set, get,) => ({
    ...partialPaymentStoreInitialState,
    setOperationId: (id,) => { set({ operationId: id, },); },
    setAmountStr: (amountStr,) => {
      const v = amountStr.replace(/\D/ig, "",);
      if (!v || Number.isNaN(parseInt(v,),)) {
        set({ amountStr: "", },);
        return;
      }
      const fmt = formatPrice(parseInt(v,), 0,).replace(/\s₽$/, "",);
      set({
        amountStr: fmt,
      },);
    },
    setState: (state,) => { set({ state, },); },
    setErrorMsg: (errorMsg,) => { set({ errorMsg, },); },
    getParsedAmount: () => {
      const clean = get().amountStr.replace(/\D/ig, "",);
      if (clean) {
        return parseInt(clean,);
      } else {
        return 0;
      }
    },
    reset: () => {
      set(partialPaymentStoreInitialState,);
    },
  }),
  { name: "partial-payment-store", },),
);
