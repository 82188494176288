/**
 * By default, Chakra uses a Tailwind mental model for spacing, e.g.,
 * 1 unit is 4px, 10 units are 40px, etc.
 * In the initial design, basic sizes were arranged slightly differently,
 * so the spaces have been modified a bit to be more compatible with the initial design.
 * !!!!
 * If you find yourself stuck, remove these spaces from the mainTheme and retain them only in sizes.
 * This will preserve the sizes of components as related to Figma but will provide you with an opportunity
 * to use the Tailwind sizing model in the rest of the application.
 */
export const space = {
  space: {
    px: "1px",
    1: "4px",
    7: "32px",
    8: "36px",
    9: "40px",
    10: "52px",
    17: "68px",
  },
};
