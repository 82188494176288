import { Box, Button, HStack, Icon, Stack, Text, useToast, Flex, } from "@chakra-ui/react";
import { InformationCircleIcon, } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient, } from "@tanstack/react-query";
import { cardBindingLinkQueryConfig, cardsQueryConfig, } from "../../../../api";
import { DebitCardLabel, } from "../../../shared/DebitCardLabel";
import { useIsMounted, } from "../../hooks";
import { useCardsStore, } from "../../../../store/cardsStore.ts";
import { useEffect, } from "react";

const toastId = "cardListFetchError";

export interface ICardListModalProps {
  readonly?: boolean;
}

export function CardListModal({ readonly, }: ICardListModalProps,) {
  const queryClient = useQueryClient();

  const selectedCard = useCardsStore(state => state.selectedCardId,);
  const selectCard = useCardsStore(state => state.selectCard,);

  const toast = useToast({ id: toastId, },);
  // This hook is used to prevent promise resolving after unmounting the component.
  const isMounted = useIsMounted();

  const cardsQuery = useQuery({
    ...cardsQueryConfig(),
    select: data => [...data.slice(-1,), ...data.slice(0, -1,),],
  },);

  useEffect(() => {
    if (cardsQuery.isSuccess && !selectedCard) {
      const lastCardId = cardsQuery.data[cardsQuery.data.length - 1]?.paymentInstrumentId;
      if (lastCardId) {
        selectCard(cardsQuery.data[cardsQuery.data.length - 1].paymentInstrumentId,);
      }
    }
  }, [cardsQuery.isSuccess,],);

  const linkQuery = useQuery({
    ...cardBindingLinkQueryConfig(),
    // fetch only on user interaction
    enabled: false,
  },);

  const handleAddCard = () => {
    linkQuery
      .refetch({ throwOnError: true, },)
      .then((data,) => {
        if (data.isSuccess && isMounted) {
          window.open(data.data.url, "_blank", "noopener noreferrer",);
          void queryClient.invalidateQueries({
            queryKey: ["cardBindingLink",],
          },);
        }
      },)
      .catch(() => {
        toast({
          description: "Не удалось загрузить данные, попробуйте ещё раз или повторите чуть позже.",
        },);
      },);
  };

  return (
    <Flex direction="column" py={2} h="100%">
      {readonly && (
        <Box bg="gray.50" p={4} borderRadius="lg">
          <HStack spacing={4} alignItems="flex-start">
            <Box>
              <Icon as={InformationCircleIcon} boxSize={6} color="brand.500" />
            </Box>
            <Box>
              <Text>В дату платежа спишем с основной карты. Если оплата не пройдёт, попробуем со следующей карты.</Text>
            </Box>
          </HStack>
        </Box>
      )}
      <Stack spacing={2} py={2}>
        {cardsQuery.isSuccess && cardsQuery.data.map((card, i,) => (
          <DebitCardLabel
            variant="listItem"
            isDefault={readonly && i === 0}
            isSelected={!readonly && selectedCard === card.paymentInstrumentId}
            onClick={() => {
              !readonly && selectCard(card.paymentInstrumentId,);
            }}
            text={`${card.cardBrand} *${card.cardNumber.slice(-4,)}`}
            key={card.paymentInstrumentId}
          />
        ),)}
      </Stack>
      <Box flex="1" />
      <Box mt="69px">
        <Button isLoading={linkQuery.isFetching} colorScheme="brand" w="100%" onClick={handleAddCard}>
          Добавить новую карту
        </Button>
      </Box>
    </Flex>
  );
}
