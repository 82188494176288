import { isRouteErrorResponse, useRouteError, } from "react-router-dom";
import { AbsoluteCenter, Box, Button, Heading, Text, Image, Center, Stack, } from "@chakra-ui/react";
import exclam from "../assets/exclamation.svg";
import nf from "../assets/notFound.svg";

/* Error page placeholder for later development */
export function ErrorPage() {
  const error = useRouteError();
  console.log(error,);

  if (isRouteErrorResponse(error,) && error.status === 404) {
    return (
      <Box h="100vh" position="relative">
        <AbsoluteCenter>
          <Box>
            <Stack spacing={4}>
              <Center>
                <Image src={nf} />
              </Center>
              <Box>
                <Heading textAlign="center" mb={2}>
                  Страница не найдена
                </Heading>
                <Text textAlign="center">
                  В адресе есть ошибка или страница удалена
                </Text>
              </Box>
            </Stack>
          </Box>
        </AbsoluteCenter>
      </Box>
    );
  }

  return (
    <Box h="100vh" position="relative">
      <AbsoluteCenter>
        <Box>
          <Stack spacing={4}>
            <Center>
              <Image src={exclam} />
            </Center>
            <Box>
              <Heading textAlign="center" mb={2}>
                Что-то пошло не так
              </Heading>
              <Text textAlign="center">
                Попробуйте ещё раз или повторите чуть позже
              </Text>
            </Box>
            <Button
              colorScheme="black"
              w="100%"
              onClick={() => { window.location.reload(); }}
            >
              Обновить
            </Button>
          </Stack>
        </Box>
      </AbsoluteCenter>
    </Box>
  );
}
