import { Button, Text, useInterval, } from "@chakra-ui/react";
import { usePersistStore, } from "../../../../store/persistStore";
import { useOTPMutationReaction, } from "../PhoneNumberForm/hooks";
import { useLoginFormStore, } from "../../../../store/loginFormStore";

/**
 * A timer that displays the number of seconds remaining before a user can request the SMS code again.
 */
export function SMSResendTimer() {
  const timer = usePersistStore(state => state.timer,);
  const tick = usePersistStore(state => state.tick,);
  const getEscapedPhoneNumber = useLoginFormStore(state => state.getEscapedPhoneNumber,);

  const sendOtp = useOTPMutationReaction();

  useInterval(tick, 1000,);

  const handleResendClick = () => {
    sendOtp.mutate({ phone: getEscapedPhoneNumber(), },);
  };

  if (timer === 0) {
    return (
      <Button
        onClick={handleResendClick}
        colorScheme="brand"
        variant="link"
      >
        Отправить код еще раз
      </Button>
    );
  }

  return (
    <Text size="sm" color="gray.500" align="center">
      Повторить отправку через
      {" "}
      {timer}
      {" "}
      сек
    </Text>
  );
}
