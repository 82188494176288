import { useMutation, } from "@tanstack/react-query";
import { addDeviceMutationFn, NetworkError, } from "../../../../../api";
import { useEffect, } from "react";
import { usePinFormState, } from "./usePinFormState.ts";
import { EPinState, } from "../../../../../store/pinFormStore.ts";
import { PIN_ERROR_MESSAGES, } from "../utils";
import { useAuthenticateDeviceMutationReaction, } from "./useAuthenticateDeviceMutationReaction.ts";
import { usePersistStore, } from "../../../../../store/persistStore.ts";

export function useAddDeviceMutationReaction() {
  const pinFormState = usePinFormState();
  const persistStore = usePersistStore();
  const authDevice = useAuthenticateDeviceMutationReaction();
  const addDevice = useMutation({
    mutationKey: ["addDevice",],
    mutationFn: addDeviceMutationFn,
  },);

  useEffect(() => {
    switch (addDevice.status) {
      case "success": {
        authDevice.mutate({ pin: pinFormState.pin, fingerprint: persistStore.fingerprint, },);
        return;
      }
      case "error": {
        const error = addDevice.error as NetworkError;
        if (error.status === 456) {
          switch (error.internalCode) {
            case 7070: {
              pinFormState.setState(EPinState.Initial,);
              pinFormState.setError(PIN_ERROR_MESSAGES.networkError,);
            }
          }
        }
      }
    }
  }, [addDevice.status,],);

  return addDevice;
}
