import { useEffect, } from "react";
import { NetworkError, otpRequestMutationFn, } from "../../../../../api";
import { EPhoneFormState, } from "../../../../../store/slices/phoneFormSliceCreator";
import { useMutation, } from "@tanstack/react-query";
import { usePhoneFormState, } from "./usePhoneFormState";
import { useToast, } from "@chakra-ui/react";
import { PHONE_ERROR_MESSAGES, } from "../utils";
import { usePersistStore, } from "../../../../../store/persistStore";

const toastId = "backend-error";

/**
 * Executes an OTP request mutation.
 * This method is responsible for handling the response of the OTP mutation request.
 * It updates the phone form state and shows appropriate error messages or navigation.
 */
export function useOTPMutationReaction() {
  const toast = useToast();
  const phoneForm = usePhoneFormState();
  const resetTimer = usePersistStore(state => state.resetTimer,);

  const sendOtp = useMutation({
    mutationKey: ["requestOTP",],
    mutationFn: otpRequestMutationFn,
  },);

  useEffect(() => {
    switch (sendOtp.status) {
      case "success": {
        resetTimer();
        phoneForm.setFormState(EPhoneFormState.submitted,);
        return;
      }
      case "error": {
        const error = sendOtp.error as NetworkError;
        // 2000 - validation
        // 7006 - already submitted
        if (error.status === 456) {
          switch (error.internalCode) {
            case 7006: {
              // Corner case. SMS already delivered to user and
              // API in cool down now, but user still trying to
              // submit same phone number
              phoneForm.setFormState(EPhoneFormState.submitted,);
              // api doesn't return SMS cooldown time from the API
              // so, we're just resetting timer here. Because there is no other way
              // to get this time.
              resetTimer();
              return;
            }
            case 2000: {
              phoneForm.setFormState(EPhoneFormState.initial,);
              phoneForm.setErrorMessage(PHONE_ERROR_MESSAGES.default,);
              return;
            }
          }
        }
        // all other cases
        // Unexpected server error (.e.g 500) occurred
        if (!toast.isActive(toastId,)) {
          toast({
            id: toastId,
            description: PHONE_ERROR_MESSAGES.unexpectedServerError,
          },);
        }
        phoneForm.setFormState(EPhoneFormState.initial,);
        return;
      }
      default:
        return;
    }
  }, [sendOtp.status,],);

  return sendOtp;
}
