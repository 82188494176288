import { useEffect, } from "react";
import { EPhoneFormState, } from "../../../../../store/slices/phoneFormSliceCreator";
import { usePhoneFormState, } from "./usePhoneFormState";
import { getValidationErrorMessage, validatePhone, } from "../utils";
import { useNavigate, } from "react-router-dom";
import { useOTPMutationReaction, } from "./useOTPMutationReaction";
import { RoutesMap, } from "../../../../../routes";

/**
 * Executes a reaction based on the current state of the phone form.
 */
export function usePhoneFormSateReaction() {
  const phoneForm = usePhoneFormState();
  const navigate = useNavigate();

  const sendOtp = useOTPMutationReaction();

  useEffect(() => {
    switch (phoneForm.formState) {
      case EPhoneFormState.initial: {
        sendOtp.reset();
        return;
      }
      case EPhoneFormState.validating: {
        const isValid = validatePhone(phoneForm.getEscapedPhoneNumber(),);
        if (isValid) {
          phoneForm.setFormState(EPhoneFormState.validated,);
          return;
        }
        phoneForm.setFormState(EPhoneFormState.initial,);
        phoneForm.setErrorMessage(getValidationErrorMessage(phoneForm.getEscapedPhoneNumber(),),);
        return;
      }
      case EPhoneFormState.validated: {
        phoneForm.setFormState(EPhoneFormState.submitting,);
        sendOtp.mutate({ phone: phoneForm.getEscapedPhoneNumber(), },);
        return;
      }
      case EPhoneFormState.submitted: {
        sendOtp.reset();
        navigate(RoutesMap.login.otp,);
      }
    }
  }, [phoneForm.formState,],);
}
