import { Box, Heading, HStack, Stack, } from "@chakra-ui/react";
import { ECardVariant, FuturePaymentCard, } from "./FuturePaymentCard";
import { formatPrice, } from "../../../../utils/formatPrice";
import { FuturePaymentsEmptyBlock, } from "./FuturePaymentsEmptyBlock";
import { usePaymentsStore, } from "../../../../store/paymentsStore";
import { useRepaymentSchedules, } from "./hooks";
import { formatDate, } from "../../../../utils/formatDate";
import { useNavigate, } from "react-router-dom";
import { RoutesMap, } from "../../../../routes";
import { memo, } from "react";
import { useFlag, } from "@unleash/proxy-client-react";

/**
 * Block encapsulating future payments view logic.
 */
function FuturePaymentsBlockComponent() {
  const successFulPayments = usePaymentsStore(state => state.successfulPayments,);
  const removeSuccessfulPayment = usePaymentsStore(state => state.removePaymentInfoById,);
  const { schedule, sortedSchedule, operations, operation, } = useRepaymentSchedules();
  const navigate = useNavigate();
  const isPaymentEnabled = useFlag("payments.all",);

  return (
    <Stack spacing={0}>
      <Heading size="md">
        Ближайшие платежи
      </Heading>
      <HStack
        spacing={2}
        overflowY="hidden"
        overflowX="scroll"
        py={6}
        px={4}
        mx={-4}
      >
        {successFulPayments.map(item => (
          <FuturePaymentCard
            key={item.number}
            variant={ECardVariant.Paid}
            amount={item.amount}
            company={item.partnerName}
            date={item.date}
            onCloseClick={() => { removeSuccessfulPayment(item.number,); }}
          />
        ),)}
        {!(schedule.isLoading || operations.isLoading) && sortedSchedule.length === 0 && successFulPayments.length === 0 && (
          <Box flex="1">
            <FuturePaymentsEmptyBlock active />
          </Box>
        )}
        {schedule.isSuccess && sortedSchedule.map((item, i,) => (
          <FuturePaymentCard
            overdue={item.status === 3}
            key={item.number}
            variant={isPaymentEnabled && ((item.status === 1 && i === 0) || item.status === 3)
              ? ECardVariant.Elevated
              : ECardVariant.Default}
            date={formatDate(new Date(item.date,),)}
            company={operation!.merchantName}
            amount={formatPrice(item.amount / 100, 0,)}
            onPayClick={() => {
              navigate(`${RoutesMap.payment.index}?ns=${item.number.toString()}&opid=${operation!.id.toString()}`,);
            }}
          />
        ),)}
        {(schedule.isLoading || operations.isLoading) && (
          <>
            <FuturePaymentCard isLoading />
            <FuturePaymentCard isLoading />
            <FuturePaymentCard isLoading />
          </>
        )}
      </HStack>
    </Stack>
  );
}

export const FuturePaymentsBlock = memo(FuturePaymentsBlockComponent,);
