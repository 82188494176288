import { AbsoluteCenter, Box, Button, Center, Heading, Image, Stack, Text, } from "@chakra-ui/react";
import nf from "../../assets/card_success.svg";
import { RoutesMap, } from "../../routes.tsx";
import { useNavigate, } from "react-router-dom";

export function CardConnectedPage() {
  const navigate = useNavigate();

  return (
    <Box h="100vh" position="relative">
      <AbsoluteCenter>
        <Box>
          <Stack spacing={4}>
            <Center>
              <Image src={nf} />
            </Center>
            <Box>
              <Heading textAlign="center" mb={2}>
                Карта добавлена
              </Heading>
              <Text color="secondary" textAlign="center">
                Эта карта теперь используется для оплаты ваших покупок
              </Text>
            </Box>
            <Box>
              <Stack spacing={2}>
                <Button colorScheme="black" onClick={() => { navigate(RoutesMap.index,); }}>
                  Вернуться на главную
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </AbsoluteCenter>
    </Box>
  );
}
