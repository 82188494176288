import { Box, Button, HStack, Icon, Skeleton, Text, } from "@chakra-ui/react";
import { CreditCardIcon, CheckCircleIcon, } from "@heroicons/react/24/outline";

export interface IDebitCardLabelListItemProps {
  /** this variant can be used to display information about cards in the lists */
  variant: "listItem";
  /** Will be written from the right side of the card icon */
  text?: string;
  /** For the cases when we need to display "основная" instead of the checkmark icon */
  isDefault?: boolean;
  /** For the cases when we need to display checkmark icon */
  isSelected?: boolean;
  onClick?: () => void;
}

export interface IDebitCardLabelMainProps {
  /** The Skeleton will be displayed. */
  isLoading?: boolean;
  /** these variants can be used to display the label everywhere in the app */
  variant: "error" | "default";
  /** Will be written from the right side of the card icon */
  text?: string;
  onClick?: () => void;
}

export type IDebitCardLabelProps = IDebitCardLabelListItemProps | IDebitCardLabelMainProps;

function isMain(props: IDebitCardLabelProps,): props is IDebitCardLabelMainProps {
  return ["error", "default",].includes(props.variant,);
}

function isList(props: IDebitCardLabelProps,): props is IDebitCardLabelListItemProps {
  return props.variant === "listItem";
}

/**
 * Label for the display the information about user's debit card.
 */
export function DebitCardLabel(props: IDebitCardLabelProps,) {
  return (
    <Skeleton
      isLoaded={isMain(props,) ? !props.isLoading : true}
    >
      <HStack spacing={2} py="10px" onClick={isList(props,) ? props.onClick : undefined}>
        <Icon boxSize={6} as={CreditCardIcon}></Icon>
        <Text
          flex="1"
          color={props.variant === "error" ? "error" : undefined}
        >
          {props.text}
        </Text>
        {isMain(props,) && (
          <Button colorScheme="brand" variant="link" onClick={props.onClick}>
            {props.variant === "error" ? "Добавить" : "Изменить"}
          </Button>
        )}
        {isList(props,) && (
          <Box>
            {props.isSelected && <Icon as={CheckCircleIcon} boxSize={6} color="brand.500" />}
            {props.isDefault && "Основная"}
          </Box>
        )}
      </HStack>
    </Skeleton>
  );
}
