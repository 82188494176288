import { defineStyleConfig, } from "@chakra-ui/react";

/**
 * Changes default font-size of the label component.
 */
export const FormLabel = defineStyleConfig({
  baseStyle: {
    fontSize: "sm",
  },
},);
