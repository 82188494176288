import { useRef, } from "react";
import { IOperationsQueryResponse, manualQuery, operationByIdQueryConfig, operationsQueryConfig, } from "../../../api";
import { useQuery, } from "@tanstack/react-query";

export type TCachedOperationData = Omit<IOperationsQueryResponse["data"][0], "dateNextPayment">;

/**
 * This hook will return the cached operation data if exists.
 *
 * Operation can be loaded throw the operation list or directly by the id.
 * If a user comes from the main page or from the operation list, we already
 * have all required data in the cache.
 */
export function useGetOperationFromCache({ operationId, }: { operationId: number },) {
  const operationInfo = useRef<TCachedOperationData>();

  const operationsQuery = useQuery({
    ...manualQuery(operationsQueryConfig(),),
    // will not be loaded if the cache is empty!
    // we will use this endpoint only to read existing information from cache!
    // if empty - run operationByIdQuery instead
    enabled: false,
    select: data =>
      data.data.find(operation => operation.id === operationId,)!,
  },);

  const operationQuery = useQuery({
    ...operationByIdQueryConfig(operationId.toString(),),
    enabled: !operationsQuery.isSuccess,
  },);

  // there is no need for useState or additional renders!
  // when operations or operationQuery loads,
  // the page will be rendered anyway useReq is completely enough
  if (operationsQuery.isSuccess) {
    operationInfo.current = operationsQuery.data;
  } else if (operationQuery.isSuccess) {
    const { operation, } = operationQuery.data;
    operationInfo.current = {
      createDate: operation.createDate,
      id: parseInt(operation.number,),
      merchantName: operation.merchantName,
      operationStatus: operation.operationStatus,
      amount: operation.amount,
      termInfo: operation.termInfo,
      currencyCode: operation.currencyCode,
      macrocategory: operation.macrocategory,
      smallIconLink: operation.smallIconLink,
    };
  }

  return {
    isLoading: operationsQuery.isSuccess ? false : (operationsQuery.isLoading || operationQuery.isLoading),
    isSuccess: operationsQuery.isSuccess || operationQuery.isSuccess,
    data: operationInfo.current,
  };
}
