import { Box, } from "@chakra-ui/react";
import { OperationsList, } from "../../components/features/OperationsList";
import { OperationsPageFilters, OperationsPageHeader, } from "../../components/features/OperationsPage";
import { redirect, } from "react-router-dom";
import { RoutesMap, } from "../../routes.tsx";
import { prepareUnleashClient, unleashClient, } from "../../unleash";

export function OperationsPage() {
  return (
    <div>
      <Box py={2}>
        <OperationsPageHeader />
      </Box>
      <Box py={4}>
        <OperationsPageFilters />
      </Box>
      <Box py={2}>
        <OperationsList emptyBlockVariant="tiny" />
      </Box>
    </div>
  );
}

OperationsPage.loader = async () => {
  await prepareUnleashClient();
  if (!unleashClient.isEnabled("operations.history",)) {
    return redirect(RoutesMap.index,);
  }
  return null;
};
