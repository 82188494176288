import { StateCreator, } from "zustand";

export interface IOtpSliceState {
  /** time in seconds */
  timer: number;
  setTimer: (timer: number) => void;
  resetTimer: () => void;
  /** timer decrement */
  tick: () => void;
}

const OTP_TIMER_DEFAULT_VALUE_SEC = 60;

/**
 * Contains information about the OTP timer.
 */
export const otpSliceCreator: StateCreator<IOtpSliceState> = set => ({
  timer: OTP_TIMER_DEFAULT_VALUE_SEC,
  setTimer: (value,) => { set(() => ({ timer: value, }),); },
  resetTimer: () => { set(() => ({ timer: OTP_TIMER_DEFAULT_VALUE_SEC, }),); },
  tick: () => { set(state => ({ timer: Math.max(state.timer - 1, 0,), }),); },
});
