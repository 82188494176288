import { QueryClient, } from "@tanstack/react-query";
import { PROD, } from "../ENV.ts";

/** Application default stale time */
export const DEFAULT_STALE_TIME_MS = 300;
/** Extended stale time for queries that requires more cache window (e.g. dependent queries) */
export const EXTENDED_STALE_TIME_MS = 1000;

/**
 * Default query client of the application.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: PROD,
      staleTime: DEFAULT_STALE_TIME_MS,
    },
  },
},);

/**
 * Query client for testing purposes.
 */
export const testQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: PROD,
      staleTime: DEFAULT_STALE_TIME_MS,
      gcTime: 0,
    },
    mutations: {
      gcTime: 0,
    },
  },
},);
