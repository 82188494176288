import { EffectCallback, useEffect, useRef, useState, } from "react";

/**
 * In the strict mode, React fire all hooks twice. This is a workaround to run the effect only once.
 *
 * !!!! Use it only when it's mandatory to run the effect only once. !!!!
 * If you don't sure use useEffect()!
 *
 * !!! No deps are supported.
 * This is not a mistake!
 */
export const useEffectOnce = (effect: EffectCallback,) => {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false,);
  const renderAfterCalled = useRef(false,);
  const [_, setVal,] = useState<number>(0,);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal(val => val + 1,);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, [],);
};
