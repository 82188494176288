import { usePinFormState, } from "./usePinFormState.ts";
import { EPinState, } from "../../../../../store/pinFormStore.ts";
import { useEffect, } from "react";
import { getValidationErrorMessage, } from "../utils";
import { useAddDeviceMutationReaction, } from "./useAddDeviceMutationReaction.ts";
import { usePersistStore, } from "../../../../../store/persistStore.ts";
import { RoutesMap, } from "../../../../../routes.tsx";
import { useNavigate, } from "react-router-dom";
import { useAuthenticateDeviceMutationReaction, } from "./useAuthenticateDeviceMutationReaction.ts";

export function usePinFormStateReaction() {
  const pinForm = usePinFormState();
  const persistStore = usePersistStore();
  const addDevice = useAddDeviceMutationReaction();
  const authDevice = useAuthenticateDeviceMutationReaction();
  const navigate = useNavigate();

  useEffect(() => {
    switch (pinForm.state) {
      case EPinState.Initial: {
        break;
      }
      case EPinState.Validating: {
        const errorMessage = getValidationErrorMessage(pinForm.pin,);
        if (errorMessage) {
          pinForm.setState(EPinState.Initial,);
          pinForm.setError(errorMessage,);
          return;
        }
        pinForm.setState(EPinState.Validated,);
        break;
      }
      case EPinState.Validated: {
        pinForm.setState(EPinState.Submitting,);
        if (persistStore.deviceJWT) {
          authDevice.mutate({ pin: pinForm.pin, fingerprint: persistStore.fingerprint, },);
          return;
        } else {
          addDevice.mutate({ pin: pinForm.pin, fingerprint: persistStore.fingerprint, },);
          return;
        }
      }
      case EPinState.Submitted: {
        pinForm.reset();
        navigate(RoutesMap.index,);
        return;
      }
    }
  }, [pinForm.state,],);
}
