import { Input, } from "@chakra-ui/react";
import { useMaskito, } from "@maskito/react";
import { useEffect, useState, } from "react";
import { noop, } from "../../../utils";
import { MaskitoOptions, } from "@maskito/core";

/**
 * +7 (000) 000-00-00
 */
const phoneMask = {
  mask: ["+", "7", " ", "(", "9", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/,],
  preprocessors: [
    ({ elementState, data, },) => {
      const { value, selection, } = elementState;
      // prevents mask erase.
      if (value.length === 0) {
        return {
          elementState: {
            selection,
            value: "+7 (9",
          },
          data,
        };
      }
    },
  ],
} as MaskitoOptions;

export interface IPhoneNumberInputProps {
  onChange?(value: string): void;
  value?: string;
}

/**
 * Component for inputting phone numbers. It provides:
 * * A mask for Russian phone numbers (e.g., +7).
 * * Handling for user inputs.
 * * Utilizes @maskito/react as a dependency.
 */
export function PhoneNumberInput({ onChange = noop, value = "", }: IPhoneNumberInputProps,) {
  const inputRef = useMaskito({ options: phoneMask, },);
  const [phone, setPhone,] = useState(value,);
  const [isMounted, setIsMounted,] = useState(false,);

  useEffect(() => {
    // omit initial fire. don't use isMounted as deps
    if (isMounted) onChange(phone,);
  }, [phone,],);

  useEffect(() => {
    setIsMounted(true,);
  }, [],);

  return (
    <Input
      ref={inputRef}
      name="phone"
      type="tel"
      placeholder="Введите номер телефона"
      value={phone}
      onFocus={() => { !phone && setPhone("+7 (9",); }}
      onInput={(e,) => {
        if (e.currentTarget.value) {
          setPhone(e.currentTarget.value,);
        }
      }}
    />
  );
}
