import { StateCreator, } from "zustand";
import { clearPhone, } from "../../components/features/LoginPage/PhoneNumberForm/utils";

export enum EPhoneFormState {
  initial,
  validating,
  validated,
  submitting,
  submitted,
}

export interface IPhoneFormSlice {
  phoneNumber: string;
  phoneFormState: EPhoneFormState;
  errorMessage: string;
  setPhoneNumber: (phoneNumber: string) => void;
  clearPhoneForm: () => void;
  setPhoneFormState: (state: EPhoneFormState) => void;
  setErrorMessage: (errorMessage: string) => void;
  getEscapedPhoneNumber: () => string;
}

/**
 * Contains information about the phone number form.
 */
export const phoneFormSliceCreator: StateCreator<IPhoneFormSlice> = (set, get,) => ({
  phoneNumber: "",
  errorMessage: "",
  phoneFormState: EPhoneFormState.initial,
  setPhoneNumber: (phoneNumber,) => {
    set(() => ({ phoneNumber, }),);
  },
  clearPhoneForm: () => {
    set(() => ({ phoneNumber: "", phoneFormState: EPhoneFormState.initial, }),);
  },
  setPhoneFormState: (state: EPhoneFormState,) => {
    set(() => ({ phoneFormState: state, }),);
  },
  setErrorMessage: (message,) => {
    set(() => ({ errorMessage: message, }),);
  },
  getEscapedPhoneNumber: () => {
    return clearPhone(get().phoneNumber,);
  },
});
