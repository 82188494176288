import { Box, Button, HStack, Icon, Text, } from "@chakra-ui/react";
import { ChevronLeftIcon, } from "@heroicons/react/24/solid";
import { useNavigate, } from "react-router-dom";

export function OperationsPageHeader() {
  const navigate = useNavigate();

  return (
    <HStack alignItems="center"spacing={4}>
      <Box>
        <Button onClick={() => { navigate(-1,); }} variant="circle" mb={2}>
          <Icon as={ChevronLeftIcon} boxSize={6} />
        </Button>
      </Box>
      <Box flex={1}>
        <Text align="center">
          Ваши покупки
        </Text>
      </Box>
      <Box boxSize={12} />
    </HStack>
  );
}
