import { usePersistStore, } from "../store/persistStore";

interface IConfiguration {
  url: string;
  headers?: Record<string, string>;
  // body will be strictly typed at query time.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  method?: string;
  query?: Record<string, string>;
}

/**
 * Network error type guard.
 *
 * @see NetworkError
 */
export function isNetworkError(e: Error,): e is NetworkError {
  return e.name === "NetworkError";
}

/**
 * Default error class for any network or API errors.
 *
 * To determine if the error is a network error, use the `isNetworkError` function.
 *
 * if there is an internal code in the response, it will be stored in the `internalCode` property.
 * By the internal code value you can determine the type of error.
 * If it's not present, the error is the network error, otherwise it's an API error.
 * @see isNetworkError
 */
export class NetworkError extends Error {
  name = "NetworkError";
  json?: unknown;
  internalCode?: number;
  constructor(public status: number, public message: string,) {
    super(message,);
    try {
      this.json = JSON.parse(message,);
      this.internalCode = (this.json as { code: number }).code;
    } catch (_e) { /* Message is not JSON. Do Nothing. */ }
  }
}

/**
 * Query and mutation requests runner.
 *
 * JWT token for authorization will be passed from zustand persist storage
 *
 * @see usePersistStore
 *
 * @param url - Full URL to the API endpoint
 * @param body - For POST requests as JS Object
 * @param method - HTTP request method
 * @param headers - HTTP request headers
 */
export async function RunQueryFor<Response = unknown,>({ url, body, method = "GET", headers, query, }: IConfiguration,) {
  const urlInstance = new URL(url,);
  if (query && typeof query === "object") {
    Object.keys(query,).forEach((key,) => {
      urlInstance.searchParams.set(key, query[key],);
    },);
  }
  const jwt = usePersistStore.getState().deviceJWT ?? usePersistStore.getState().jwt;
  const resp = await fetch(urlInstance.toString(), {
    method,
    headers: {
      ...headers,
      ...(jwt ? { Authorization: `Bearer ${jwt}`, } : {}),
    },
    ...(body ? { body: JSON.stringify(body,), } : {}),
  },);
  if (!resp.ok) {
    throw new NetworkError(resp.status, await resp.text(),);
  }
  try {
    return await resp.json() as Response;
  } catch (_e) {
    return {} as Response;
  }
}
