import { useMutation, useQueryClient, } from "@tanstack/react-query";
import { PAYMENT_INVALIDATION_TAG, paymentMutationFn, } from "../../../api";
import { formatPrice, } from "../../../utils/formatPrice";
import { formatDate, } from "../../../utils/formatDate";
import { useToast, } from "@chakra-ui/react";
import { usePaymentsStore, } from "../../../store/paymentsStore";
import { useGetOperationFromCache, } from "./index";
import { EPaymentInfoStatus, IPaymentInfo, } from "../../../types/paymentInfo";
import { useCardsStore, } from "../../../store/cardsStore";

/**
 * This hook is responsible for payment mutation.
 *
 * It's providing a preconfigured mutation with paymentMutationFn.
 *
 * @see paymentMutationFn
 */
export function usePaymentMutation({
  paymentInfo = [],
  operationId,
}: { operationId: number; paymentInfo?: IPaymentInfo[] },) {
  const toast = useToast({ id: "payment-error", },);
  const queryClient = useQueryClient();
  const addSuccessfulPaymentInfo = usePaymentsStore(state => state.addPaymentInfo,);
  const totalPaymentAmount = paymentInfo.reduce((acc, item,) => acc + item.amount, 0,);
  const operation = useGetOperationFromCache({ operationId: operationId, },);
  const selectedCardId = useCardsStore(state => state.selectedCardId,);

  function getPaymentType() {
    if (paymentInfo.length === 0) {
      // partial payment.
      return "overdue";
    }

    if (paymentInfo.length === 1) {
      return paymentInfo[0].status === EPaymentInfoStatus.Overdue ? "overdue" : "planned";
    }

    if (paymentInfo.length > 1) {
      if (paymentInfo.every(item => item.status === EPaymentInfoStatus.Overdue,)) {
        return "overdue";
      } else {
        return "full";
      }
    }

    return "planned";
  }

  const mutation = useMutation({
    mutationFn: ({ amount, }: { amount?: number } = {},) => {
      if (paymentInfo.length === 0 && !amount) {
        throw new Error("No payment info or amount provided",);
      }

      return paymentMutationFn({
        amount: amount ?? totalPaymentAmount,
        operationId: operationId.toString(),
        paymentType: getPaymentType(),
        paymentInstrumentId: selectedCardId,
      },);
    },
    onSuccess: () => {
      // to show the green card with successful payment info on the main screen
      paymentInfo.forEach((item,) => {
        addSuccessfulPaymentInfo({
          amount: formatPrice(item.amount / 100, 0,),
          date: formatDate(new Date(item.date,),),
          partnerName: operation.data!.merchantName,
          number: item.number,
        },);
      },);

      void queryClient.invalidateQueries({
        queryKey: [PAYMENT_INVALIDATION_TAG,],
      },);
    },
    onError: () => {
      if (!toast.isActive("payment-error",)) {
        toast({
          description: "Не удалось оплатить, проверьте баланс и попробуйте ещё раз или добавьте другую карту",
        },);
      }
    },
  },);

  return {
    mutation,
    totalPaymentAmount,
  };
}
