import { Box, Card, CardBody, HStack, Image, Skeleton, Text, } from "@chakra-ui/react";
import { formatPrice, } from "../../../../utils/formatPrice";
import { CircleImagePlaceholder, } from "../../../shared/CircleImagePlaceholder";

export interface IOperationProps {
  /** If true skeleton will be shown */
  isLoading?: boolean;
  /** url for company logo  */
  logoSrc?: string;
  /** company/patner name  */
  companyName?: string;
  /** date of payment */
  paymentDate?: string;
  /** amount of payment */
  amount?: number;
  /** installment period */
  termDate?: string;
}

/**
 * Operation information card.
 *
 * Operation in terms of application is an installment.
 */
export function Operation({
  isLoading,
  termDate,
  paymentDate,
  amount,
  logoSrc,
  companyName,
}: IOperationProps,) {
  return (
    <Skeleton isLoaded={!isLoading}>
      <Card variant="filled" borderRadius="lg" cursor="pointer">
        <CardBody px={4} py={3}>
          <HStack spacing={3}>
            {logoSrc && <Image objectFit="contain" src={logoSrc} boxSize="48px" borderRadius="full" />}
            {!logoSrc && <CircleImagePlaceholder /> }
            <Box flex="1">
              <Text>{companyName}</Text>
              <Text fontSize="sm" color="secondary">
                Платеж
                {" "}
                {paymentDate}
              </Text>
            </Box>
            <Box>
              <Text align="right">
                {formatPrice(amount ?? 0,)}
              </Text>
              <Text align="right" fontSize="sm" color="secondary">
                на
                {" "}
                {termDate}
              </Text>
            </Box>
          </HStack>
        </CardBody>
      </Card>
    </Skeleton>
  );
}
