import { createBrowserRouter, } from "react-router-dom";
import { MainLayout, } from "./pages/MainLayout";
import { ErrorPage, } from "./pages/ErrorPage";
import { LoginPage, } from "./pages/Login/LoginPage";
import { LoginPageLayout, } from "./pages/Login/LoginPageLayout";
import { OtpPage, } from "./pages/Login/OtpPage";
import { PinPage, } from "./pages/Login/PinPage";
import { MainPage, } from "./pages/MainPage";
import { HowToPage, } from "./pages/HowToPage";
import { OperationPage, } from "./pages/Operations/OperationPage";
import { PaymentPage, } from "./pages/Payment/PaymentPage";
import { PartialPaymentPage, } from "./pages/Payment/PartialPaymentPage";
import { PaymentSuccessPage, } from "./pages/Payment/PaymentSuccessPage";
import { OperationsPage, } from "./pages/Operations/OperationsPage";
import { ProfilePage, } from "./pages/ProfilePage";
import { CardConnectedPage, } from "./pages/Card/Connected.tsx";
import { CardErrorPage, } from "./pages/Card/Error.tsx";

export const RoutesMap = {
  index: "/",
  payment: {
    index: `/payment`,
  },
  login: {
    index: "/login",
    otp: "/login/otp",
    pin: "/login/pin",
  },
  howto: "/howto",
  profile: "/profile",
  card: {
    connected: "/card/connected",
    error: "/card/error",
  },
  operations: {
    index: "/operations",
    operation: "/operations/:id",
    payment: {
      index: "/operations/:id/payment",
      partial: "/operations/:id/payment/partial",
      success: "/operations/:id/payment/success",
    },
  },
} as const;

/**
 * This is the application's routing tree. Page components contain loader functions to reduce
 * the size of the codebase and to separate logic. Typically, loader functions are used
 * to execute `queryClient.prefetch` with ReactQuery.
 */
export const routes = () => createBrowserRouter([{
  path: "/",
  element: <MainLayout />,
  loader: MainLayout.loader,
  errorElement: <ErrorPage />,
  children: [{
    path: RoutesMap.card.connected,
    element: <CardConnectedPage />,
  }, {
    path: RoutesMap.card.error,
    element: <CardErrorPage />,
  }, {
    path: RoutesMap.profile,
    element: <ProfilePage />,
  }, {
    path: RoutesMap.operations.payment.partial,
    element: <PartialPaymentPage />,
    loader: PartialPaymentPage.loader,
  }, {
    path: RoutesMap.operations.payment.success,
    element: <PaymentSuccessPage />,
    loader: PaymentSuccessPage.loader,
  }, {
    path: RoutesMap.howto,
    element: <HowToPage />,
    loader: HowToPage.loader,
  }, {
    path: RoutesMap.index,
    element: <MainPage />,
    loader: MainPage.loader,
    children: [{
      path: RoutesMap.payment.index,
      element: <PaymentPage />,
      loader: PaymentPage.loader,
    },],
  }, {
    path: RoutesMap.operations.index,
    children: [{
      path: RoutesMap.operations.index,
      element: <OperationsPage />,
      loader: OperationsPage.loader,
    }, {
      path: RoutesMap.operations.operation,
      element: <OperationPage />,
      loader: OperationPage.loader,
      children: [{
        path: RoutesMap.operations.payment.index,
        element: <PaymentPage />,
        loader: PaymentPage.loader,
      },],
    },],
  }, {
    path: RoutesMap.login.index,
    element: <LoginPageLayout />,
    loader: LoginPageLayout.loader,
    children: [{
      path: RoutesMap.login.index,
      element: <LoginPage />,
      loader: LoginPage.loader,
    }, {
      path: RoutesMap.login.otp,
      element: <OtpPage />,
      loader: OtpPage.loader,
    }, {
      path: RoutesMap.login.pin,
      element: <PinPage />,
      loader: PinPage.loader,
    },],
  },],
},],);
