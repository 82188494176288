import { Box, Button, HStack, Icon, Text, } from "@chakra-ui/react";
import { ChevronLeftIcon, } from "@heroicons/react/24/solid";
import { useNavigate, } from "react-router-dom";
import { useQuery, } from "@tanstack/react-query";
import { operationByIdQueryConfig, } from "../../../../api";
import { usePartialPaymentStore, } from "../../../../store/partialPaymentStore";

export function PartialPaymentPageHeader() {
  const operationId = usePartialPaymentStore(state => state.operationId,);
  const navigate = useNavigate();
  const operation = useQuery(operationByIdQueryConfig(operationId.toString(),),);

  return (
    <HStack alignItems="center" py={2} spacing={4}>
      <Box>
        <Button onClick={() => { navigate(-1,); }} variant="circle" mb={2}>
          <Icon as={ChevronLeftIcon} boxSize={6} />
        </Button>
      </Box>
      <Box flex={1}>
        <Text align="center">
          Платеж за покупку в
          {" "}
          {operation.data!.operation.merchantName}
        </Text>
      </Box>
      <Box boxSize={12} />
    </HStack>
  );
}
