import { Box, } from "@chakra-ui/react";
import { OperationPageHeader, } from "../../components/features/OperationPage/OperationHeader";
import { OperationMainInfo, } from "../../components/features/OperationPage/OperationMainInfo";
import { PaymentList, } from "../../components/features/OperationPage/PaymentList";
import { ProductList, } from "../../components/features/OperationPage/ProductList";
import { Outlet, redirect, } from "react-router-dom";
import { PaymentCardsView, } from "../../components/features/PaymentCardsView";
import { prepareUnleashClient, unleashClient, } from "../../unleash";
import { RoutesMap, } from "../../routes.tsx";
import { useFlag, } from "@unleash/proxy-client-react";

export function OperationPage() {
  const isPaymentCardsEnabled = useFlag("payments.cards",);
  const isNextPaymentsEnabled = useFlag("payments.next_payments_block",);

  return (
    <Box py={2}>
      <OperationPageHeader />
      <OperationMainInfo />
      {isNextPaymentsEnabled && (
        <PaymentList />
      )}
      {isPaymentCardsEnabled && (
        <Box mt={6}>
          <PaymentCardsView />
        </Box>
      )}
      <ProductList />
      <Outlet />
      <Box mb={6} />
    </Box>
  );
}

OperationPage.loader = async () => {
  await prepareUnleashClient();
  if (!unleashClient.isEnabled("operations.operation_page",)) {
    return redirect(RoutesMap.index,);
  }
  return null;
};
