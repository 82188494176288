import { AbsoluteCenter, Box, Heading, HStack, Icon, Link, Stack, Text, } from "@chakra-ui/react";
import { PinForm, } from "../../components/features/LoginPage/PinForm";
import { usePersistStore, } from "../../store/persistStore.ts";
import { ChevronRightIcon, } from "@heroicons/react/24/outline";
import { Link as RouterLink, } from "react-router-dom";
import { RoutesMap, } from "../../routes.tsx";

export function PinPage() {
  const deviceJWT = usePersistStore(state => state.deviceJWT,);
  const clearToken = usePersistStore(state => state.clearDeviceJwt,);

  return (
    <Box position="relative" h="calc(100vh - 84px * 2)">
      <AbsoluteCenter axis="both">
        <Stack spacing={9} minW="300px">
          <div>
            <Heading mb={2} textAlign="center">{!deviceJWT ? "Придумайте код" : "Введите код"}</Heading>
            <Text size="sm" color="gray.500" align="center">
              Для быстрого входа в кабинет
            </Text>
          </div>
          <PinForm />
          <Box>
            <HStack alignItems="center" justifyContent="center">
              <Link onClick={() => { clearToken(); }} as={RouterLink} to={RoutesMap.login.index} color="secondary">
                Не помню код
              </Link>
              <Icon color="secondary" boxSize={4} as={ChevronRightIcon} />
            </HStack>
          </Box>
        </Stack>
      </AbsoluteCenter>
    </Box>
  );
}

PinPage.loader = () => null;
