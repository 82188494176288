import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ScaleFade,
  Slide,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PropsWithChildren, useEffect, useRef, useState, } from "react";
import { AppModal, IAppModalProps, } from "./AppModal";
import { IModalContext, modalContext, } from "./hooks";
import { noop, } from "../../../../utils";

enum EAnimationState {
  ShouldEnter,
  Entering,
  Entered,
  ShouldExit,
  Exiting,
  Exited,
}

/**
 * This is the app modal provider.
 * Only one modal can be shown at a time.
 * We also can't use default chakra modals because we need to show
 * a different variation of the modal window.
 *  * In mobile, it will look like a slider that will appear from the bottom of the screen.
 *  * In desktop, it will look like a regular modal window.
 *
 * The name AppModal is chosen to avoid conflicts with the Chakra UI Modal component.
 *
 * The component also provides a tricky animation for the mobile slider.
 * Animation flow:
 *  1. ShouldEnter - the modal is about to appear.
 *  Render the modal wrapper and portal.
 *  2. Entering - the modal is appearing.
 *  The animation is running at this step and the modal can't be closed.
 *  3. Entered - the modal is fully appeared. No animation is running.
 *  4. ShouldExit - the modal is about to disappear.
 *  5. Exiting - the modal is disappearing, and animation is running.
 *  6. Exited - the modal is fully disappeared.
 *
 * @see AppModal
 * @see useAppModal
 */
export function AppModalProvider(props: PropsWithChildren,) {
  // in this state, we are preserving modal state, to be able to show the animation and keep the modal
  // in the tree wile exiting.
  const [currentModal, setCurrentModal,] = useState<IAppModalProps | null>(null,);
  const [animationState, setAnimationState,] = useState(EAnimationState.Exited,);
  // inside the modal closure, we can pass values only by reference.
  const animationStateRef = useRef(animationState,);
  // this state is used to control the slider animation - true - show, false - hide.
  const [sliderControl, setSliderControl,] = useState(false,);
  // prevent closing the modal window from the backdrop or on close.
  const [isBlocked, setIsBlocked,] = useState(false,);
  // because it's a part of modal closure, it can be transferred into the callback only by reference!
  const blockRef = useRef(isBlocked,);
  // because it's a part of modal closure, it can be transferred into the callback only by reference!
  const closeCb = useRef(noop,);
  const layout = useBreakpointValue({ base: "mobile", md: "desktop", },);

  const handleBackdropClick = () => {
    if (!isBlocked && animationStateRef.current === EAnimationState.Entered) {
      setAnimationState(EAnimationState.ShouldExit,);
    }
  };

  useEffect(() => {
    animationStateRef.current = animationState;
  }, [animationState,],);

  useEffect(() => {
    blockRef.current = isBlocked;
  }, [isBlocked,],);

  const modalContextValue: IModalContext = {
    showModal: (modal: IAppModalProps,) => {
      if (modal.onClose) {
        closeCb.current = modal.onClose;
      } else {
        closeCb.current = noop;
      }
      window.scrollTo({ top: 0, left: 0, behavior: "smooth", },);
      const onClose = () => {
        if (!blockRef.current) {
          setAnimationState(EAnimationState.ShouldExit,);
        }
      };
      const block = (state: boolean,) => {
        setIsBlocked(state,);
      };
      setCurrentModal({ ...modal, onClose, block, },);
    },
    hideModal: () => { handleBackdropClick(); },
    isModalOpen: animationState !== EAnimationState.Exited,
  };

  useEffect(() => {
    if (currentModal !== null && animationState === EAnimationState.Exited) {
      setAnimationState(EAnimationState.ShouldEnter,);
    }
  }, [currentModal,],);

  useEffect(() => {
    switch (animationState) {
      case EAnimationState.ShouldEnter: {
        setSliderControl(true,);
        return;
      }
      case EAnimationState.ShouldExit: {
        setSliderControl(false,);
        return;
      }
      case EAnimationState.Exited: {
        setCurrentModal(null,);
        closeCb.current();
        return;
      }
    }
  }, [animationState,],);

  const handleAnimationStart = () => {
    if (animationState === EAnimationState.ShouldEnter) {
      setAnimationState(EAnimationState.Entering,);
    }
    if (animationState === EAnimationState.ShouldExit) {
      setAnimationState(EAnimationState.Exiting,);
    }
  };

  const handleAnimationCompletion = () => {
    if (animationState === EAnimationState.Entering) {
      setAnimationState(EAnimationState.Entered,);
    }
    if (animationState === EAnimationState.Exiting) {
      setAnimationState(EAnimationState.Exited,);
    }
  };

  return (
    <modalContext.Provider value={modalContextValue}>
      {props.children}
      {/* IOS notch color */}
      {animationState !== EAnimationState.Exited && <meta name="theme-color" content="#5c5c5c" />}
      <Box
        display={animationState === EAnimationState.Exited ? "none" : "block"}
        position="absolute"
        top="0"
        left="0"
        h="100vh"
        w="100vw"
        bg="blackAlpha.700"
        zIndex={10}
        id="modlaProvider"
        onClick={handleBackdropClick}
      >
        {layout === "desktop" && (
          <ScaleFade
            in={sliderControl}
            onAnimationComplete={handleAnimationCompletion}
            onAnimationStart={handleAnimationStart}
          >
            <Modal isOpen={!!currentModal} onClose={handleBackdropClick}>
              <ModalContent>
                <ModalCloseButton />
                <ModalHeader textAlign="center">{currentModal?.header}</ModalHeader>
                {currentModal && (
                  <ModalBody py={6}>
                    {currentModal.content?.(currentModal.onClose!, currentModal.block!, isBlocked,)}
                  </ModalBody>
                )}
              </ModalContent>
            </Modal>
          </ScaleFade>
        )}
        {layout === "mobile" && (
          <Slide
            motionProps={{
              onAnimationComplete: handleAnimationCompletion,
              onAnimationStart: handleAnimationStart,
            }}
            direction="bottom"
            in={sliderControl}
            style={{ zIndex: 10, }}
          >
            <Box onClick={(e,) => { e.stopPropagation(); }}>
              {!!currentModal && <AppModal {...currentModal} isBlocked={isBlocked} />}
            </Box>
          </Slide>
        )}
      </Box>
    </modalContext.Provider>
  );
}
