import { Box, Button, Icon, } from "@chakra-ui/react";
import { ChevronLeftIcon, } from "@heroicons/react/24/solid";
import { RoutesMap, } from "../routes";
import { Link, } from "react-router-dom";
import { HowToBlock, } from "../components/features/HowToPage";

/**
 * This page displays "how to use denum" information.
 */
export function HowToPage() {
  return (
    <Box>
      <Box py={2}>
        <Button as={Link} to={RoutesMap.index} variant="circle" mb={2}>
          <Icon as={ChevronLeftIcon} boxSize={6} />
        </Button>
      </Box>
      <HowToBlock />
    </Box>
  );
}

HowToPage.loader = () => null;
