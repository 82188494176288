import { Box, Button, Center, Flex, Heading, HStack, Icon, Link, Text, } from "@chakra-ui/react";
import { useEffect, useRef, useState, } from "react";
import { ChevronLeftIcon, ChevronRightIcon, } from "@heroicons/react/24/outline";
import { CheckCircleIcon, } from "@heroicons/react/24/solid";
import { formatPrice, } from "../../../../utils/formatPrice";
import { PageLoader, } from "../../../shared/PageLoader";
import { useGetOperationFromCache, usePaymentMutation, } from "../../hooks";
import { EPaymentInfoStatus, IPaymentInfo, } from "../../../../types/paymentInfo";
import { generatePath, Link as RouterLink, } from "react-router-dom";
import { RoutesMap, } from "../../../../routes";
import { CardListModal, } from "../CardListModal";
import { useCardsStore, } from "../../../../store/cardsStore";
import { useQuery, } from "@tanstack/react-query";
import { cardsQueryConfig, manualQuery, } from "../../../../api";
import { PaymentCardPicker, } from "../../PaymentCardPicker";
import { useFlag, } from "@unleash/proxy-client-react";

export interface IPaymentModalProps {
  /** id of an operation from operations endpoint */
  operationId: number;
  /**
   * Array of items with information about payment schedule.
   *
   * If one item is passed, the modal window will be in the single payment mode.
   * You can read about single payment mode in the PaymentModal description
   * @see PaymentModal
   * */
  paymentInfo: IPaymentInfo[];
  onClose?: () => void;
  /** Blocking the modal window. It can't be closed if the state is true */
  block?: (state: boolean) => void;
  /** If true - the modal window is blocked and can't be closed from backdrop or API */
  isBlocked?: boolean;
}

/**
 * This is the payment modal window. It's encapsulating all logic and UI for the payment process.
 */
export function PaymentModal({ operationId, onClose, block, paymentInfo, }: IPaymentModalProps,) {
  const isPaymentCardsEnabled = useFlag("payments.cards",);

  const ref = useRef<HTMLDivElement>(null,);
  const selectedCardId = useCardsStore(state => state.selectedCardId,);
  const selectCard = useCardsStore(state => state.selectCard,);
  const cardsQuery = useQuery(manualQuery(cardsQueryConfig(),),);
  const operation = useGetOperationFromCache({ operationId, },);
  const { totalPaymentAmount, mutation: paymentMutation, } = usePaymentMutation({
    paymentInfo,
    operationId,
  },);

  const [showList, setShowList,] = useState(false,);
  const [listHeight, setListHeight,] = useState<number>(0,);

  const cardNotAssigned = !selectedCardId;

  useEffect(() => {
    block?.(paymentMutation.isPending,);
  }, [paymentMutation.isPending,],);

  useEffect(() => {
    if (cardsQuery.isSuccess) {
      selectCard(cardsQuery.data[cardsQuery.data.length - 1].paymentInstrumentId,);
    }
  }, [cardsQuery.isSuccess,],);

  if (!operation.isSuccess) {
    // this literally impossible case on this step.
    return <PageLoader />;
  }

  const handleCardChangeClick = () => {
    setShowList(true,);
    if (ref.current) {
      setListHeight(ref.current.offsetHeight,);
    }
  };

  if (showList) {
    return (
      <Flex minHeight={listHeight} flexDir="column" flex="1 1 0">
        <Icon
          as={ChevronLeftIcon}
          boxSize={6}
          position="absolute"
          top="5"
          onClick={() => { setShowList(false,); }}
        />
        <Box py={2}>
          <Heading>
            Выберите карту для оплаты или добавьте новую
          </Heading>
        </Box>
        <CardListModal />
      </Flex>
    );
  }

  const handlePaymentClick = (): void => {
    paymentMutation.mutate({},);
  };

  const urlToPartialPayment = generatePath(RoutesMap.operations.payment.partial, { id: operationId.toString(), },);

  return (
    <div ref={ref}>
      <Box pt={8} pb={17}>
        <Center>
          <Box>
            <Heading textAlign="center" fontSize="8xl">
              {formatPrice(totalPaymentAmount / 100, 0,)}
            </Heading>
            <Text textAlign="center">
              Платёж за покупку
              <br />
              в
              {" "}
              { operation.data!.merchantName }
            </Text>
            { !paymentMutation.isSuccess && paymentInfo[0].status === EPaymentInfoStatus.Overdue && (
              <Link
                as={RouterLink}
                to={urlToPartialPayment.toString()}
                onClick={onClose}
              >
                <Text align="center" py={4} color="secondary">
                  Оплатить часть
                  <Icon display="inline" as={ChevronRightIcon} boxSize={5} verticalAlign="bottom" />
                </Text>
              </Link>
            )}
            { paymentMutation.isSuccess && (
              <HStack spacing={2} py={6}>
                <Icon as={CheckCircleIcon} boxSize={6} color="success" />
                <Text color="success">Оплачено успешно</Text>
              </HStack>
            )}
          </Box>
        </Center>
      </Box>
      {!paymentMutation.isSuccess && isPaymentCardsEnabled && <PaymentCardPicker onClick={handleCardChangeClick} />}
      <Box pt={4}>
        {!paymentMutation.isSuccess && (
          <Button
            w="100%"
            colorScheme={cardNotAssigned ? "gray" : "brand"}
            isLoading={paymentMutation.isPending}
            isDisabled={cardNotAssigned}
            onClick={handlePaymentClick}
          >
            {cardNotAssigned ? "Добавьте карту" : "Оплатить"}
          </Button>
        )}
        {paymentMutation.isSuccess && (
          <Button w="100%" colorScheme="gray" onClick={onClose}>Закрыть</Button>
        )}
      </Box>
    </div>
  );
}
