/**
 * This is a wrapper for the queryConfig that simply put the staleTime to Infinity.
 * You can use it with any query to just read data from cache without any request to the server
 *
 * To refetch data, you can use query.refetch()
 *
 * @example
 * const operations = useQuery(manualQuery(operationsQueryConfig()));
 */
export function manualQuery<T,>(queryConfig: T,): T {
  return {
    ...queryConfig,
    staleTime: Infinity,
  };
}
