import { nationalitiesQueryConfig, userProfileDocumentsQueryConfig, userProfileQueryConfig, } from "../api";
import { useQuery, useQueryClient, } from "@tanstack/react-query";
import { Box, Button, Heading, HStack, Icon, Link, Stack, Text, } from "@chakra-ui/react";
import { ProfilePageHeader, } from "../components/features/ProfilePage/ProfilePageHeader";
import { ProfileTextCell, } from "../components/features/ProfilePage/ProfileTextCell";
import { useNavigate, } from "react-router-dom";
import { usePersistStore, } from "../store/persistStore";
import { RoutesMap, } from "../routes";
import { formatDate, } from "../utils/formatDate.ts";
import { capitalize, } from "../utils";
import { DEV, MODE, VITE_APP_VERSION, } from "../ENV.ts";
import { ChevronRightIcon, DocumentIcon, } from "@heroicons/react/24/outline";

export function ProfilePage() {
  const queryClient = useQueryClient();
  const userProfileQuery = useQuery({
    ...userProfileQueryConfig(),
    initialData: {
      id: 0,
    },
    refetchOnMount: "always",
  },);
  const useNationalitiesQuery = useQuery({
    ...nationalitiesQueryConfig(),
    select: data => data.find(({ id, },) => id === userProfileQuery.data.nationalityId,)?.name,
  },);
  const userDocumentsQuery = useQuery({
    ...userProfileDocumentsQueryConfig(),
  },);

  const navigate = useNavigate();
  const clearToken = usePersistStore(state => state.clearJwt,);
  const clearDeviceToken = usePersistStore(state => state.clearDeviceJwt,);

  const handleLogout = () => {
    clearToken();
    clearDeviceToken();
    queryClient.clear();
    navigate(RoutesMap.login.index,);
  };

  const isFetching = userProfileQuery.isFetching && useNationalitiesQuery.isFetching;

  const fullName = userProfileQuery.data.name
    ? [
        userProfileQuery.data.surname!,
        userProfileQuery.data.name,
        userProfileQuery.data.patronymic!,
      ].filter(Boolean,).map(capitalize,).join(" ",)
    : "";

  return (
    <div id="profilePage">
      <ProfilePageHeader />
      <Box>
        <Heading pt={8} pb={2} fontSize="xl">Контакты</Heading>
        <Stack spacing={4} py={4}>
          <ProfileTextCell
            isLoaded={!isFetching}
            label="Телефон"
            text={userProfileQuery.data.phone}
          />
          <ProfileTextCell
            isLoaded={!isFetching}
            label="Email"
            text={userProfileQuery.data.email}
          />
        </Stack>
      </Box>
      <Box>
        <Heading pt={8} pb={2} fontSize="xl">Личные данные</Heading>
        <Stack spacing={4} py={4}>
          <ProfileTextCell
            isLoaded={!isFetching}
            label="ФИО"
            text={fullName}
          />
          <ProfileTextCell
            isLoaded={!isFetching}
            label="Дата рождения"
            text={userProfileQuery.data.birthdate
              ? formatDate(new Date(userProfileQuery.data.birthdate,), { year: "numeric", },)
              : ""}
          />
          <ProfileTextCell
            isLoaded={!isFetching}
            label="Гражданство"
            text={capitalize(useNationalitiesQuery.data ?? "",)}
          />
          {userProfileQuery.data.passportSeries && userProfileQuery.data.passportNumber && (
            <ProfileTextCell
              isLoaded={!isFetching}
              label="Серия и номер паспорта"
              text={`${userProfileQuery.data.passportSeries} ${userProfileQuery.data.passportNumber}`}
            />
          )}
          <ProfileTextCell
            isLoaded={!isFetching}
            label="Дата выдачи паспорта"
            text={
              userProfileQuery.data.passportIssueDate
                ? formatDate(new Date(userProfileQuery.data.passportIssueDate,), { year: "numeric", },)
                : ""
            }
          />
          <ProfileTextCell
            isLoaded={!isFetching}
            label="Код подразделения"
            text={userProfileQuery.data.passportDivisionCode ?? ""}
          />
          <ProfileTextCell
            isLoaded={!isFetching}
            label="Адрес регистрации"
            text={userProfileQuery.data.registrationAddressString ?? ""}
          />
        </Stack>
      </Box>

      <Box>
        <Heading pt={8} pb={2} fontSize="xl">Документы</Heading>
        {userDocumentsQuery.isFetching && (
          <ProfileTextCell
            isLoaded={false}
            label="-"
            text="-"
          />
        )}
        {userDocumentsQuery.isSuccess && (
          <HStack py={4} alignItems="flex-start">
            <Icon as={DocumentIcon} boxSize={6} />
            <Link color="primary" href={userDocumentsQuery.data.url}>
              Договор розничной купли-продажи дистанционным способом
            </Link>
            <Icon as={ChevronRightIcon} boxSize={4} />
          </HStack>
        )}
        {userDocumentsQuery.isError && (
          <HStack py={4} alignItems="flex-start">
            <Text color="text">Не удалось загрузить</Text>
            <Box flex="1 1 0" />
            <Link colorScheme="primary" onClick={() => userDocumentsQuery.refetch()}>
              Повторить
            </Link>
          </HStack>
        )}
      </Box>

      {(MODE === "develop" || DEV) && (
        <Box pt={10}>
          <ProfileTextCell
            label="Версия приложения:"
            // in local development mode, import.meta.env.VITE_APP_VERSION is not defined
            // in test environment it will be set to the short commit sha
            text={VITE_APP_VERSION ?? "Не задана"}
          />
        </Box>
      )}
      {(MODE === "develop" || DEV) && (
        <Box pt={10}>
          <Button onClick={() => {
            throw new Error("Sentry Example Error",);
          }}
          >
            Generate Test Errors
          </Button>
        </Box>
      )}
      <Box pt={10} pb={4}>
        <Button w="100%" onClick={handleLogout}>
          Выйти
        </Button>
      </Box>
    </div>
  );
}
