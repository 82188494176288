import { StateCreator, } from "zustand";

export interface IFingerprintSliceState {
  fingerprint: string;
  deviceJWT?: string;
  setFingerprint: (fnp: string) => void;
  setDeviceJwt: (jwt: string) => void;
  clearDeviceJwt: () => void;
}

export const fingerPrintSliceCreator: StateCreator<IFingerprintSliceState> = set => ({
  fingerprint: "",
  setFingerprint: (fnp,) => {
    set({ fingerprint: fnp, },);
  },
  setDeviceJwt: (jwt,) => {
    set({ deviceJWT: jwt, },);
  },
  clearDeviceJwt: () => {
    set(({ deviceJWT, ...rest },) => rest, true,);
  },
});
