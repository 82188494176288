import { Box, Heading, HStack, Icon, Image, Stack, Text, } from "@chakra-ui/react";
import { Link, } from "react-router-dom";
import MvideoImg from "../../../assets/mvideo.png";
import MegafonImg from "../../../assets/megafon.jpeg";
import EldoradoImg from "../../../assets/eldorado.png";
import {
  ChatBubbleBottomCenterTextIcon,
  CreditCardIcon,
  InformationCircleIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";

const partnersItems = [
  { img: MvideoImg, text: "МВидео", link: "https://www.mvideo.ru/", },
  { img: MegafonImg, text: "Мегафон", link: "https://megafon.ru/", },
  { img: EldoradoImg, text: "Эльдорадо", link: "https://www.eldorado.ru/", },
];

const listItems = [
  {
    icon: ShoppingBagIcon,
    text: "Приходите в магазин наших партнеров. С собой возьмите паспорт",
  },
  {
    icon: ChatBubbleBottomCenterTextIcon,
    text: "Скажите сотруднику на кассе, что хотите купить товар в рассрочку Денум Плати частями",
  },
  {
    icon: CreditCardIcon,
    text: "Сотрудник заполнит анкету, а вам нужно будет внести первый платеж с карты",
  },
  {
    icon: InformationCircleIcon,
    text: "Обычно в рассрочку продают при покупке от 3 000 ₽ или 5 000 ₽",
    highlighted: true,
  },
];

/**
 * Component that shows how to pay in installments with Denum
 */
export function HowToBlock() {
  return (
    <Box>
      <Box>
        <Heading size="md" py={2}>
          Как платить частями с Denum
        </Heading>
        <Stack spacing={4} py={4}>
          {listItems.map(({ icon, text, highlighted, },) => {
            return (
              <Box key={text} py={1} bg={highlighted ? "gray.50" : ""} borderRadius="lg" p={highlighted ? 4 : 0}>
                <HStack spacing={4}>
                  <Icon color="brand.500" as={icon} boxSize={6} />
                  <Text>
                    {text}
                  </Text>
                </HStack>
              </Box>
            );
          },)}
        </Stack>
      </Box>
      <Box mt={7}>
        <Heading size="md" mb={6}>
          Где воспользоваться
        </Heading>
        <HStack spacing={2}>
          {partnersItems.map(({ text, img, link, },) => (
            <Link to={link} key={img}>
              <Stack spacing={2}>
                <Image objectFit="cover" borderRadius="md" maxH="88px" minW="114px" src={img} />
                <Text fontSize="sm">{text}</Text>
              </Stack>
            </Link>
          ),)}
        </HStack>
      </Box>
    </Box>
  );
}
