import { AbsoluteCenter, Box, Center, Heading, Image, Stack, Text, } from "@chakra-ui/react";
import maintenanceImage from "../../../assets/maintenance.svg";

export function MaintenancePage() {
  return (
    <Box h="100vh" position="relative">
      <AbsoluteCenter>
        <Box>
          <Stack spacing={4}>
            <Center>
              <Image src={maintenanceImage}></Image>
            </Center>
            <Box>
              <Heading textAlign="center" mb={2}>
                Проводим технические работы
              </Heading>
              <Text size="mb" color="secondary" textAlign="center">
                Ждем вас чуть позже
              </Text>
            </Box>
          </Stack>
        </Box>
      </AbsoluteCenter>
    </Box>
  );
}
