import { create, } from "zustand";
import { devtools, } from "zustand/middleware";

export interface ISuccessfulPaymentInfo {
  amount: string;
  partnerName: string;
  date: string;
  // kind of id... I believe
  number: number;
}

export interface IPaymentsState {
  /**
   * Array of successful payments.
   * This data is used to hide and display successful payments on the main page.
   */
  successfulPayments: ISuccessfulPaymentInfo[];
  addPaymentInfo: (info: ISuccessfulPaymentInfo) => void;
  removePaymentInfoById: (id: number) => void;
  reset: () => void;
}

/**
 * This store provides data about successful payments. It can be used for any other state management
 * that needs to be present on the PaymentModal and payment components.
 *
 * @see PaymentModal
 */
export const usePaymentsStore = create<IPaymentsState>()(
  devtools((set, get,) => ({
    successfulPayments: [],
    addPaymentInfo: (info: ISuccessfulPaymentInfo,) => {
      set({
        successfulPayments: [
          ...get().successfulPayments.filter(rec => rec.number !== info.number,),
          info,
        ],
      },);
    },
    removePaymentInfoById: (id,) => {
      set({ successfulPayments: get().successfulPayments.filter(({ number, },) => number !== id,), },);
    },
    reset: () => {
      set({ successfulPayments: [], },);
    },
  }),
  { name: "payments-store", },
  ),
);
