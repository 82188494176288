import { Box, Skeleton, Text, } from "@chakra-ui/react";
import { ReactNode, } from "react";

export interface IProfileTextCellProps {
  text: ReactNode;
  label: string;
  isLoaded?: boolean;
}

export function ProfileTextCell({ text, label, isLoaded = true, }: IProfileTextCellProps,) {
  if (!text && isLoaded) {
    return null;
  }

  return (
    <Skeleton isLoaded={isLoaded} minH={9}>
      <Box>
        <Text color="secondary" fontSize="sm">{label}</Text>
        <Text>{text}</Text>
      </Box>
    </Skeleton>
  );
}
