import React, { useEffect, } from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType, } from "react-router-dom";
import { VITE_APP_VERSION, VITE_SENTRY_DSN, VITE_SENTRY_ENV_NAME, } from "./ENV.ts";

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    },),
    Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,
  enabled: ["prod", "preprod",].includes(VITE_SENTRY_ENV_NAME,),
  release: VITE_APP_VERSION,
  environment: VITE_SENTRY_ENV_NAME,
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\/api/,],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
},);

ReactDOM.createRoot(document.getElementById("root",)!,).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
