import { usePinFormState, } from "./usePinFormState.ts";
import { useMutation, } from "@tanstack/react-query";
import { authDeviceMutationFn, NetworkError, } from "../../../../../api";
import { useEffect, } from "react";
import { EPinState, } from "../../../../../store/pinFormStore.ts";
import { PIN_ERROR_MESSAGES, } from "../utils";
import { usePersistStore, } from "../../../../../store/persistStore.ts";

export function useAuthenticateDeviceMutationReaction() {
  const pinFormState = usePinFormState();
  const persistStore = usePersistStore();
  const authDevice = useMutation({
    mutationKey: ["addDevice",],
    mutationFn: authDeviceMutationFn,
  },);

  useEffect(() => {
    switch (authDevice.status) {
      case "success": {
        pinFormState.setState(EPinState.Submitted,);
        persistStore.setDeviceJwt(authDevice.data.token,);
        return;
      }
      case "error": {
        const error = authDevice.error as NetworkError;
        if (error.status === 456) {
          switch (error.internalCode) {
            case 7070: {
              pinFormState.setState(EPinState.Initial,);
              pinFormState.setError(PIN_ERROR_MESSAGES.networkError,);
            }
          }
        }
      }
    }
  }, [authDevice.status,],);

  return authDevice;
}
