import { AbsoluteCenter, Box, Portal, Spinner, } from "@chakra-ui/react";

/**
 * Full page sized loader. It can be used in some cases when you can't display skeletons.
 *
 * Use only when it's necessary, because it blocks the whole page.
 * Skeletons are always a better choice.
 */
export function PageLoader() {
  return (
    <Portal>
      <Box w="100vw" h="100vh" position="absolute" top="0" left="0" bg="white" zIndex={100}>
        <AbsoluteCenter>
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="black"
          />
        </AbsoluteCenter>
      </Box>
    </Portal>
  );
}
