/**
 * Base font properties for chakra.
 * fontSizes are arranged based on figma.
 */
export const fonts = {
  fonts: {
    body: "Geologica",
    heading: "Geologica",
  },
  fontSizes: {
    "xs": "12px",
    "sm": "14px",
    "md": "16px",
    "lg": "18px",
    "xl": "20px",
    "2xl": "22px",
    "3xl": "24px",
    "4xl": "26px",
    "5xl": "28px",
    "6xl": "32px",
    "7xl": "38px",
    "8xl": "40px",
    "9xl": "48px",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 300,
    medium: 300,
    semibold: 300,
    bold: 500,
    extrabold: 500,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    base: "20px",
    huge: "50px",
  },
};
