import { createSearchParams, generatePath, useNavigate, } from "react-router-dom";
import { PAYMENT_NUMBER_PARAM_NAME, } from "../../../../pages/Payment/PaymentPage";
import { RoutesMap, } from "../../../../routes";

/**
 * Convenient wrapper for navigation to the payment page.
 */
export function useNavigateToPayment() {
  const navigate = useNavigate();

  return (paymentNumbers: number[], operationId: number,) => {
    const sp = createSearchParams({
      [PAYMENT_NUMBER_PARAM_NAME]: paymentNumbers.join(",",),
    },);
    const url = generatePath(RoutesMap.operations.payment.index, {
      id: operationId.toString(),
    },);
    navigate(`${url}?${sp.toString()}`,);
  };
}
