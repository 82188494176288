import { UnleashClient, } from "@unleash/proxy-client-react";
import { VITE_UNLEASH_POLL_INTERVAL_SEC, VITE_UNLEASH_PUB_KEY, VITE_UNLEASH_URL, } from "../ENV.ts";

const unleashConf = {
  url: VITE_UNLEASH_URL,
  clientKey: VITE_UNLEASH_PUB_KEY,
  refreshInterval: parseInt(VITE_UNLEASH_POLL_INTERVAL_SEC,), // How often (in seconds) the client should poll the proxy for updates
  appName: "Default",
};

export const unleashClient = new UnleashClient(unleashConf,);

/**
 * We need to await this every time because React router and unleash client living
 * in a different timelines, if we want to use unleashClient
 * in the React router, we must synchronize them first.
 */
export async function prepareUnleashClient() {
  if (unleashClient.isReady()) {
    return Promise.resolve();
  }
  if (unleashClient.getError()) {
    return Promise.reject(new Error("unleash unavailable",),);
  }
  return new Promise((resolve, reject,) => {
    unleashClient.once("ready", resolve,);
    unleashClient.once("error", reject,);
  },);
}
