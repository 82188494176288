import { Box, HStack, Icon, Text, } from "@chakra-ui/react";
import { ExclamationCircleIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import { ReactNode, } from "react";

export interface IToastProps {
  message: ReactNode;
  onClose: () => void;
  icon?: ReactNode;
}

/**
 * Application default Toast component. Props are inherited from chakra toast
 */
export function Toast({ message, onClose, icon, }: IToastProps,) {
  return (
    <Box bg="black" color="white" p={2} borderRadius="md">
      <Box position="relative">
        <Icon onClick={onClose} as={XMarkIcon} boxSize={6} position="absolute" top={0} right={0} />
        <HStack spacing={3} p={2} alignItems="normal">
          <Box>{icon ? icon : <Icon boxSize={6} as={ExclamationCircleIcon} />}</Box>
          <Box>
            <Text>
              {message}
            </Text>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
}
