import { AbsoluteCenter, Box, Button, Center, Heading, Image, Stack, Text, } from "@chakra-ui/react";
import nf from "../../assets/card_error.svg";
import { useNavigate, } from "react-router-dom";
import { RoutesMap, } from "../../routes.tsx";

export function CardErrorPage() {
  const navigate = useNavigate();

  return (
    <Box h="100vh" position="relative">
      <AbsoluteCenter>
        <Box>
          <Stack spacing={4}>
            <Center>
              <Image src={nf} />
            </Center>
            <Box>
              <Heading textAlign="center" mb={2}>
                Не удалось привязать карту
              </Heading>
              <Text color="secondary" textAlign="center">
                Проверьте баланс и попробуйте ещё раз или добавьте другую карту
              </Text>
            </Box>
            <Box>
              <Stack spacing={2}>
                <Button colorScheme="gray" onClick={() => { navigate(RoutesMap.index,); }}>
                  Вернуться на главную
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </AbsoluteCenter>
    </Box>
  );
}
