import { cssVar, defineStyle, defineStyleConfig, } from "@chakra-ui/react";

const $startColor = cssVar("skeleton-start-color",);
const $endColor = cssVar("skeleton-end-color",);

const brand = defineStyle({
  _light: {
    [$startColor.variable]: "colors.gray.50",
    [$endColor.variable]: "colors.gray.100",
  },
  _dark: {
    [$startColor.variable]: "colors.gray.50",
    [$endColor.variable]: "colors.gray.100",
  },
},);

export const Skeleton = defineStyleConfig({
  variants: { brand, },
  defaultProps: { variant: "brand", },
},);
