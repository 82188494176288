import { Button, Heading, HStack, Icon, Stack, } from "@chakra-ui/react";
import { ChevronRightIcon, } from "@heroicons/react/24/outline";
import { OperationsList, } from "../../OperationsList";
import { Link as RouterLink, } from "react-router-dom";
import { RoutesMap, } from "../../../../routes";
import { EFiltersState, useOperationListFilters, } from "../../../../store/operationListFilters.ts";
import { useEffect, } from "react";

/**
 * Operation is installment or purchase in terms of application logic.
 *
 * This block is responsible for displaying the list of all instalments and
 * header for the main page
 */
export function OperationsBlock() {
  const setFilters = useOperationListFilters(state => state.setFilters,);

  useEffect(() => {
    setFilters(EFiltersState.Active,);
  }, [],);

  return (
    <Stack spacing={2}>
      <HStack mb={2}>
        <Heading flex="1" size="md">Ваши покупки</Heading>
        <Button
          as={RouterLink}
          to={RoutesMap.operations.index}
          variant="link"
          colorScheme="gray"
          rightIcon={<Icon color="sceondary" as={ChevronRightIcon} />}
        >
          Все
        </Button>
      </HStack>
      <OperationsList />
    </Stack>
  );
}
