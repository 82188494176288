import { RunQueryFor, } from "./runQueryFn";
import { ApiBuilder, } from "./apiBuilder";
import { queryOptions, } from "@tanstack/react-query";
import { EXTENDED_STALE_TIME_MS, } from "./queryClient";

/**
 * All queries that are related to the payment should have this tag.
 * It should be used to invalidate each query after the payment is done.
 */
export const PAYMENT_INVALIDATION_TAG = "payment";

/**
 * Represents the example of configuration for the 'documents' query.
 */
export const documentsQueryConfig = (alias: string,) => ({
  queryKey: ["documents", alias,],
  queryFn: ({ queryKey, }: { queryKey: string[] },) =>
    RunQueryFor<{ queryType: string }>(ApiBuilder.queries.documents.byAlias(queryKey[1],).url,),
});

export interface ICardsIndexResponse {
  paymentInstrumentId: number;
  expirationMonth: number;
  expirationYear: number;
  cardNumber: string;
  cardHolderName: string;
  aliasName: string;
  cardBrand: string;
  processDate: string;
}

export const cardsQueryConfig = () => ({
  queryKey: ["cards",],
  queryFn: () => RunQueryFor<ICardsIndexResponse[]>(ApiBuilder.queries.cards.index,),
  // We don't need to retry this query quite often. It's enough to invalidate a cache
  // manually when a new card is added or removed.
  staleTime: 1000 * 60 * 10, // 10 min in ms
});

type operationsQueryConfigParams = Parameters<typeof ApiBuilder.queries.operations.index>[0];
const operationsQueryDefaultParams: Partial<operationsQueryConfigParams> = {
  startDate: "2019-01-01",
  perPage: 100,
  page: 0,
};

export interface IOperationsQueryResponse {
  perPage: number;
  page: number;
  data: {
    id: number;
    createDate: string;
    merchantName: string;
    amount: number;
    currencyCode: "RUB";
    operationStatus: number;
    termInfo: {
      value: string;
      unit: string;
    };
    dateNextPayment: string;
    macrocategory: string;
    smallIconLink: string;
  }[];
}

export const operationsQueryConfig = (params: Partial<operationsQueryConfigParams> = {},) => {
  const withParams = {
    endDate: new Date().toISOString().match(/^[^T]+/i,)![0],
    ...operationsQueryDefaultParams,
    ...params,
  } as operationsQueryConfigParams;
  return {
    queryKey: ["operations", withParams,] as [string, operationsQueryConfigParams,],
    queryFn: ({ queryKey, }: { queryKey: [string, operationsQueryConfigParams,] },) =>
      RunQueryFor<IOperationsQueryResponse>(ApiBuilder.queries.operations.index(queryKey[1],),),
    staleTime: EXTENDED_STALE_TIME_MS,
  };
};

export interface IRepaymentScheduleByIdResponse {
  number: number;
  date: string;
  amount: number;
  currencyCode: "RUB";
  status: number;
  actualPaymentAmount: number;
}

export const repaymentSchedulesByIdQueryConfig = (id?: number,) => queryOptions({
  queryKey: [PAYMENT_INVALIDATION_TAG, "repaymentSchedules", id!,],
  queryFn: ({ queryKey, },) =>
    RunQueryFor<IRepaymentScheduleByIdResponse[]>(
      ApiBuilder.queries.operations.byId(queryKey[2].toString(),).repaymentSchedule,
    ),
  enabled: !!id,
  staleTime: EXTENDED_STALE_TIME_MS,
},);

export interface IOperationByIdResponse {
  operation: {
    number: string;
    createDate: string;
    merchantName: string;
    amount: number;
    overdueAmount: number;
    remainingAmount: number;
    currencyCode: "RUB";
    macrocategory: string;
    operationStatus: number;
    smallIconLink: string;
    termInfo: {
      value: string;
      unit: string;
    };
  };
}

export const operationByIdQueryConfig = (id: string,) => queryOptions({
  queryKey: [PAYMENT_INVALIDATION_TAG, "operation", id,],
  queryFn: ({ queryKey, },) =>
    RunQueryFor<IOperationByIdResponse>(
      ApiBuilder.queries.operations.byId(queryKey[2].toString(),).index,
    ),
},);

export interface IOperationCommissionResponse {
  currencyCode: "RUB";
  amount: number;
}

export const operationCommissionQueryConfig = (id: string,) => queryOptions({
  queryKey: ["operationCommission", id,],
  queryFn: ({ queryKey, },) =>
    RunQueryFor<IOperationCommissionResponse>(
      ApiBuilder.queries.operations.byId(queryKey[1].toString(),).commission,
    ),
  staleTime: Infinity,
},);

export interface IOperationProductsResponse {
  items: {
    name: string;
    amount: number;
    currencyCode: "RUB";
  }[];
  totalAmount: {
    amount: number;
    currencyCode: "RUB";
  };
}

export const productsQueryConfig = (id: string,) => queryOptions({
  queryKey: ["products", id,],
  queryFn: ({ queryKey, },) =>
    RunQueryFor<IOperationProductsResponse>(
      ApiBuilder.queries.operations.byId(queryKey[1].toString(),).products,
    ),
  staleTime: EXTENDED_STALE_TIME_MS,
},);

export const cardBindingLinkQueryConfig = () => queryOptions({
  queryKey: ["cardBindingLink",],
  queryFn: () =>
    RunQueryFor<{ url: string }>({
      ...ApiBuilder.queries.cards.binding.link,
      query: {
        successCallbackUrl: `${window.location.origin}/card/connected`,
        errorCallbackUrl: `${window.location.origin}/card/error`,
      },
    },),
  staleTime: 0,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchInterval: false,
  refetchOnWindowFocus: false,
},);

export interface IUserProfileQueryResponse {
  id: number;
  phone?: string;
  name?: string;
  patronymic?: string;
  surname?: string;
  gender?: string;
  nationalityId?: number;
  birthdate?: string;
  email?: string;
  consentDistributionPersonalData?: boolean;
  consentCollectionPersonalData?: boolean;
  consentContactOffer?: boolean;
  limit?: number;
  limitUpdatedAt?: string;
  refusalByLimit?: boolean;
  refusalByLimitDaysLeft?: string;
  statusId?: number;
  passportSeries?: string;
  passportNumber?: string;
  passportIssueDate?: string;
  passportDivisionCode?: string;
  registrationAddressString?: string;
}

export const userProfileQueryConfig = () => queryOptions({
  queryKey: ["userProfile",],
  queryFn: () => RunQueryFor<IUserProfileQueryResponse>(ApiBuilder.queries.userProfiles,),
},);

export const nationalitiesQueryConfig = () => queryOptions({
  queryKey: ["nationalities",],
  queryFn: () => RunQueryFor<{ id: number; name: string }[]>(ApiBuilder.queries.nationalities,),
},);

export const userProfileDocumentsQueryConfig = () => queryOptions({
  queryKey: ["userProfileDocuments",],
  queryFn: () => RunQueryFor<{ url: string }>(ApiBuilder.queries.documents.byAlias("offer-limit",).url,),
},);
