import { RunQueryFor, } from "./runQueryFn";
import { ApiBuilder, } from "./apiBuilder";

/**
 * Submit phone number for SMS.
 * @param phone - phone number only digits without +7
 */
export const otpRequestMutationFn = async ({ phone, }: { phone: string },) => {
  return await RunQueryFor(ApiBuilder.mutations.otp.index(phone,),);
};

/**
 * Verify sms code
 * @param phone - phone number only digits without +7 (from initial request)
 * @param code - code from sms
 */
export const otpVerificationMutationFn = async ({ phone, code, }: { phone: string; code: string },) => {
  return await RunQueryFor<{ token: string }>(ApiBuilder.mutations.otp.verifications(phone, code,),);
};

/**
 * Refresh of expired token
 */
export const tokenRefreshMutationFn = async () => {
  return await RunQueryFor<{ token: string }>(ApiBuilder.mutations.token.refreshment(),);
};

export interface IPaymentMutationParams {
  operationId: string;
  paymentType: string;
  amount: number;
  paymentInstrumentId: number;
}

export const paymentMutationFn = async ({ operationId, ...rest }: IPaymentMutationParams,) => {
  return await RunQueryFor(ApiBuilder.mutations.operations.byId(operationId,).payments(rest,),);
};

export interface IDeviceMutationParams {
  fingerprint: string;
  pin: string;
}

export const addDeviceMutationFn = async ({ pin, fingerprint, }: IDeviceMutationParams,) => {
  return await RunQueryFor(ApiBuilder.mutations.devices(pin, fingerprint,),);
};

export const authDeviceMutationFn = async ({ pin, fingerprint, }: IDeviceMutationParams,) => {
  return await RunQueryFor<{ token: string }>(ApiBuilder.mutations.authenticateDevice(pin, fingerprint,),);
};
