import { Button, HStack, } from "@chakra-ui/react";
import { EFiltersState, useOperationListFilters, } from "../../../../store/operationListFilters";

export function OperationsPageFilters() {
  const filtersState = useOperationListFilters(state => state.state,);
  const setFiltersState = useOperationListFilters(state => state.setFilters,);

  const handleFilterClick = (fState: EFiltersState,) => () => {
    setFiltersState(fState,);
  };

  const getColorScheme = (relatedFiltersState: EFiltersState,) => {
    return filtersState === relatedFiltersState ? "black" : "gray";
  };

  return (
    <HStack>
      <Button
        onClick={handleFilterClick(EFiltersState.Active,)}
        py="10px"
        px={6}
        colorScheme={getColorScheme(EFiltersState.Active,)}
        h="40px"
        borderRadius="full"
      >
        Активные
      </Button>
      <Button
        onClick={handleFilterClick(EFiltersState.Completed,)}
        py="10px"
        px={6}
        colorScheme={getColorScheme(EFiltersState.Completed,)}
        h="40px"
        borderRadius="full"
      >
        Завершенные
      </Button>
    </HStack>
  );
}
