import { LoaderFunctionArgs, Outlet, useNavigate, } from "react-router-dom";
import { Container, } from "@chakra-ui/react";
import { usePersistStore, } from "../store/persistStore";
import { EJWTStatus, redirectSafe, validateJWT, } from "../utils";
import { RoutesMap, } from "../routes";
import { NetworkError, } from "../api";
import { useQueryClient, } from "@tanstack/react-query";
import { useEffect, } from "react";
import { AppModalProvider, } from "../components/features/Modals";
import { TokenRefresher, } from "../components/shared/TokenRefresher";
import { useFlag, } from "@unleash/proxy-client-react";
import { MaintenancePage, } from "../components/features/MaintenancePage";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

/**
 * Main Layout of the application. Present as a root component for an application.
 */
export function MainLayout() {
  const queryClient = useQueryClient();
  const clearToken = usePersistStore(state => state.clearJwt,);
  const navigate = useNavigate();
  const isOnMaintenance = useFlag("maintenance",);

  useEffect(() => {
    return queryClient.getQueryCache().subscribe((event,) => {
      if (event.type === "updated" && event.action.type === "error") {
        if ((event.action.error as Error).name === "NetworkError") {
          const networkError = event.action.error as NetworkError;
          switch (networkError.internalCode) {
            case 10000: {
              // 500 Internal Server Error
              throw networkError; // To Router Error Boundary
            }
            case 1001: {
              // 401 Unauthorized
              clearToken();
              navigate(RoutesMap.login.index,);
              return;
            }
            default: {
              console.error(networkError,);
              return;
            }
          }
        }
      }
    },);
  }, [],);

  if (isOnMaintenance) {
    return (
      <Container>
        <MaintenancePage />
      </Container>
    );
  }

  return (
    <AppModalProvider>
      <TokenRefresher />
      <Container>
        <Outlet />
      </Container>
    </AppModalProvider>
  );
}

/**
 * JWT Session behavior.
 * * Invalid - should be redirected to the login page;
 * * RefreshRequired - token should be refreshed;
 * * Valid - do nothing;
 * * Expired - the token is expired but present and can be refreshed by API
 */
MainLayout.loader = async ({ request, }: LoaderFunctionArgs,) => {
  const state = usePersistStore.getState();
  const deviceJwtState = validateJWT(state.deviceJWT,);

  const fp = await FingerprintJS.load();
  state.setFingerprint((await fp.get()).visitorId,);

  switch (deviceJwtState) {
    case EJWTStatus.RefreshRequired:
    case EJWTStatus.Expired: {
      return redirectSafe(RoutesMap.login.pin, request.url,);
    }
    case EJWTStatus.Invalid: {
      state.clearDeviceJwt();
      return redirectSafe(RoutesMap.login.index, request.url,);
    }
    case EJWTStatus.Valid:
      return null;
  }
};
