import { createSearchParams, generatePath, useNavigate, } from "react-router-dom";
import { RoutesMap, } from "../../../../routes";
import { PAYMENT_AMOUNT_SEARCH_PARAM, } from "../../../../pages/Payment/PaymentSuccessPage";

/**
 * Convenient wrapper for navigation to the payment page.
 */
export function useNavigateToSuccessPaymentPage() {
  const navigate = useNavigate();

  return (paymentAmount: number, operationId: number,) => {
    const sp = createSearchParams({
      [PAYMENT_AMOUNT_SEARCH_PARAM]: paymentAmount.toString(),
    },);

    const url = generatePath(
      RoutesMap.operations.payment.success,
      { id: operationId.toString(), },
    );
    navigate(`${url}?${sp.toString()}`,);
  };
}
